import React, { useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useApiFetch } from '../../../utils/api'
import Loader from '../../../components/Loader'

function OrderPayment() {

  const history = useHistory()

  const { uuid } = useParams()
  const api = useApiFetch()

  useEffect(() => {
    api('/payments/'+ uuid +'/check')
      .then((payments) => {
        const [ payment ] = payments.length ? payments : [ null ]

        if (payment) {
          if (payment.shortname === 'payu' && payment.code === 'CREATED') {

            window.location.href = payment.details.payment_url
          }
          else {
            history.replace(`/platform/orders/${uuid}/payment/check`)
          }
        }
        else {
          api('/payments/'+ uuid, { method: 'POST' })
            .then(({ payment_url }) => {
              window.location.href = payment_url
            })
        }
      })
      .catch(() => {})

  }, [])

  return (
    <Loader loaded={false}></Loader>
  )
}

export default OrderPayment
