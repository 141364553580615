import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box, FormControl, FormLabel, Input, Stack, Spacer, Checkbox, InputGroup, InputLeftElement, Icon, Button, VStack, FormErrorMessage, useToast, FormHelperText, useDisclosure } from '@chakra-ui/react'
import { MdPhone } from 'react-icons/md'

import Translate from '../../../components/Translate'
import { useApiFetch } from '../../../utils/api'
import { phonePL, phoneUK, vatEU, vatPL } from '../../../utils/regexes'
import CountryInput from '../../../components/inputs/CountryInput'
import AddressFormModalWrapper from './AddressFormModalWrapper'

function AddressForm({ onLoad: sendIsLoaded, onSubmit: redirectOnSubmit, address, asModal, refresh }) {

  const { isOpen, onOpen, onClose } = useDisclosure()

  const initialData = {
    name: '',
    phone: '',
    is_company: false,
    tax_no: '',
    street: '',
    property_no: '',
    place_no: '',
    postcode: '',
    city: '',
    country: 'PL'
  }

  const [data, setData] = useState(initialData)

  const setDefaultName = (name) => setData({ ...data, name })

  const [submitText, setSubmitText] = useState('Save')

  const [errors, setErrors] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)

  const api = useApiFetch()
  const toast = useToast()

  const handleInput = (e, name) => {
    setData({
      ...data,
      [name]: e.target ? (
        e.target.type === 'checkbox' ?
          e.target.checked
          : e.target.value
      )
        : e
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    setIsSubmitting(true)
    setErrors({})

    var currentErrors = {}

    // Check for empty keys
    const checkEmptyKeys = ['name', 'phone', 'street', 'property_no', 'postcode', 'city', ...(data.is_company ? ['tax_no'] : [])]
    Object.keys(data).forEach(field => {
      if (checkEmptyKeys.includes(field) && data[field] === '') currentErrors = { ...currentErrors, [field]: <Translate>This field can&apos;t be empty</Translate> }
    })

    // Check phone no.
    if (!phonePL(data.phone) && !phoneUK(data.phone)) currentErrors = { ...currentErrors, phone: <Translate>Incorrect phone number</Translate> }

    if (data.isCompany) {
      // Check vat tax no.
      if (!vatEU(data.tax_no) && !vatPL(data.tax_no)) currentErrors = { ...currentErrors, tax_no: <Translate>Incorrect VAT number</Translate> }
    }

    if (Object.keys(currentErrors).length !== 0) {
      setErrors(currentErrors)
      setIsSubmitting(false)
    }
    else {
      api('/client/address'+ (address ? '/'+ address : ''), {
        method: address ? 'PUT' : 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-type': 'application/json'
        }
      })
        .then(() => {
          setIsSubmitting(false)

          toast({
            title: <Translate>Data have been saved!</Translate>,
            status: 'success',
            duration: 5000,
            isClosable: true,
          })

          setData(initialData)
          refresh()
          if (asModal) onClose()
          if (redirectOnSubmit) redirectOnSubmit()
        })
        .catch(async ({ statusText, ...response }) => {

          try {
            const json = await response.json()

            let toastContent = {}

            if (json.errors) {
              setErrors(json.errors)

              toastContent = {
                title: <Translate>{ json.message }</Translate>,
              }
            }
            else {
              toastContent = {
                title: <Translate>Internal API error</Translate>,
              }
            }

            toast({
              ...toastContent,
              status: 'error',
              duration: 5000,
              isClosable: true,
            })
          }
          catch(err){
            toast({
              title: <Translate>Error occured</Translate>,
              description: <><Translate>Server response</Translate><span>:</span> <Translate>{ statusText }</Translate></>,
              status: 'error',
              duration: 5000,
              isClosable: true,
            })
          }

          setIsSubmitting(false)
        })
    }
  }

  useEffect(() => {
    if (address) {
      api(`/client/address/${address}`)
        .then((response) => {
          const {
            name,
            phone,
            is_company,
            tax_no,
            street,
            property_no,
            place_no,
            postcode,
            city,
            country
          } = response

          setData({ name, phone, isCompany: is_company, tax_no, street, property_no, place_no, postcode, city, country })

          if (sendIsLoaded) sendIsLoaded()
        })
        .catch(() => {
          api('/client')
            .then((response) => {
              const { firstname, lastname } = response

              setDefaultName(firstname + ' ' + lastname)
              setSubmitText('Add')
              if (sendIsLoaded) sendIsLoaded()
            })
            .catch(() => {})
        })
    }
    else {
      api('/client')
        .then((response) => {
          const { firstname, lastname } = response

          setDefaultName(firstname + ' ' + lastname)
          setSubmitText('Add')
          if (sendIsLoaded) sendIsLoaded()
        })
        .catch(() => {})
    }
  }, [])

  const artificialSubmit = () => handleSubmit({ preventDefault: () => {} })

  return (
    <AddressFormModalWrapper asModal={!!asModal} onSubmit={artificialSubmit} isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      <Box w={'100%'}>
        <form method='POST' onSubmit={handleSubmit}>
          <VStack spacing={4} mb={4} justify='flex-start'>

            <FormControl isInvalid={errors.name}>
              <FormLabel>
                <Translate>{ !data.is_company ? 'Full name' : 'Company name'}</Translate>
              </FormLabel>
              <Input type='text' name='name' onChange={e => handleInput(e, 'name')} value={data.name} />
              <FormErrorMessage>{ errors.name }</FormErrorMessage>
            </FormControl>

            <Spacer />

            <FormControl isInvalid={errors.phone}>
              <FormLabel>
                <Translate>Phone</Translate>
              </FormLabel>
              <InputGroup>
                <InputLeftElement size='lg' pointerEvents="none" color="gray.300" fontSize="1.2em">
                  <Icon as={MdPhone} color='gray.300'/>
                </InputLeftElement>
                <Input type='text' name='phone' onChange={e => handleInput(e, 'phone')} value={data.phone} />
              </InputGroup>
              <FormErrorMessage>{ errors.phone }</FormErrorMessage>
            </FormControl>
            <Spacer />
            <FormControl>
              <Checkbox colorScheme="brand" onChange={e => handleInput(e, 'is_company')} isChecked={data.is_company} >
                <Translate>Company</Translate>
              </Checkbox>
            </FormControl>

            {
              data.is_company ?
                <>
                  <FormControl isInvalid={errors.tax_no}>
                    <FormLabel>
                      <Translate>VAT Number</Translate>
                    </FormLabel>
                    <Input type='text' name='tax_no' onChange={e => handleInput(e, 'tax_no')} value={data.tax_no} />
                    <FormHelperText>
                      <Translate>For VAT EU, add country prefix before number, ex - PL12345678912</Translate>
                    </FormHelperText>
                    <FormErrorMessage>{ errors.tax_no }</FormErrorMessage>
                  </FormControl>
                  <Spacer />
                </>
                : null
            }

            <FormControl isInvalid={errors.street}>
              <FormLabel>
                <Translate>Street</Translate>
              </FormLabel>
              <Input type='text' name='street' onChange={e => handleInput(e, 'street')} value={data.street} />
              <FormErrorMessage>{ errors.street }</FormErrorMessage>
            </FormControl>

            <Stack direction={{ base: 'column', sm: 'row' }} justify='flex-start' w={'100%'}>
              <FormControl isInvalid={errors.property_no}>
                <FormLabel>
                  <Translate>House no</Translate>
                </FormLabel>
                <Input type='text' name='property_no' onChange={e => handleInput(e, 'property_no')} value={data.property_no} />
                <FormErrorMessage>{ errors.property_no }</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.place_no}>
                <FormLabel>
                  <Translate>Place no</Translate>
                </FormLabel>
                <Input type='text' name='place_no' onChange={e => handleInput(e, 'place_no')} value={data.place_no} />
                <FormErrorMessage>{ errors.place_no }</FormErrorMessage>
              </FormControl>
            </Stack>

            <FormControl isInvalid={errors.postcode}>
              <FormLabel>
                <Translate>Postcode</Translate>
              </FormLabel>
              <Input type='text' name='postcode' onChange={e => handleInput(e, 'postcode')} value={data.postcode} />
              <FormErrorMessage>{ errors.postcode }</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.city}>
              <FormLabel>
                <Translate>City</Translate>
              </FormLabel>
              <Input type='text' name='city' onChange={e => handleInput(e, 'city')} value={data.city} />
              <FormErrorMessage>{ errors.city }</FormErrorMessage>
            </FormControl>

            <FormControl>
              <FormLabel>
                <Translate>Country</Translate>
              </FormLabel>
              <CountryInput onChange={v => handleInput(v, 'country')} defaultValue={data.country} />
            </FormControl>

          </VStack>
          {
            !asModal ?
              <Button w={{ base: '100%', md: 'initial' }} variant='brand-solid' type='submit' isLoading={isSubmitting}>
                <Translate>{ submitText }</Translate>
              </Button>
              : null
          }
        </form>
      </Box>
    </AddressFormModalWrapper>
  )
}

AddressForm.propTypes = {
  onLoad: PropTypes.func,
  onSubmit: PropTypes.func,
  address: PropTypes.number,
  asModal: PropTypes.bool,
  refresh: PropTypes.func
}

export default AddressForm

