import React from 'react'
import PropTypes from 'prop-types'
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
import '@videojs/themes/dist/forest/index.css'

export const VideoJS = ({ options, onReady, id }) => {

  const videoRef = React.useRef(null)
  const playerRef = React.useRef(null)

  React.useEffect(() => {
    // make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current
      if (!videoElement) return

      const player = playerRef.current = videojs(videoElement, options, () => {
        onReady && onReady(player)
      })
    } else {
      // you can update player here [update player through props]
      // const player = playerRef.current;
      // player.autoplay(options.autoplay);
      // player.src(options.sources);
    }
  }, [options])

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    return () => {
      if (playerRef.current) {
        playerRef.current.dispose()
        playerRef.current = null
      }
    }
  }, [])

  return (
    <div data-vjs-player style={{ margin: '10px 0' }}>
      <video ref={videoRef} className="video-js vjs-theme-forest" id={id} />
    </div>
  )
}

export default VideoJS

VideoJS.propTypes = {
  options: PropTypes.object,
  onReady: PropTypes.func,
  id: PropTypes.string
}
