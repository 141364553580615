import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

function InPostGeoWidget({ isOpen, onClose, options, afterClose }) {
  const [inpostWidgetLoaded, setInpostWidgetLoaded] = useState(false)

  useEffect(() => {
    if (!inpostWidgetLoaded && !document.getElementById('inpost-script') && !document.getElementById('inpost-style')) {
      const inpostScript = document.createElement('script')
      inpostScript.src = 'https://geowidget.easypack24.net/js/sdk-for-javascript.js'
      inpostScript.async = true
      inpostScript.id = 'inpost-script'

      document.body.appendChild(inpostScript)

      const inpostStyle = document.createElement('link')
      inpostStyle.href = 'https://geowidget.easypack24.net/css/easypack.css'
      inpostStyle.rel = 'stylesheet'
      inpostStyle.id = 'inpost-style'

      document.head.appendChild(inpostStyle)

      setInpostWidgetLoaded(true)

      window.easyPackAsyncInit = function() {
        // eslint-disable-next-line no-undef
        easyPack.init(options && typeof options === 'object' ? options : {
          defaultLocale: 'pl',
          display: {
            showTypesFilters: false,
          },
          customDetailsCallback: function(point, modal) {
            modal.closeModal()
            onClose()
            afterClose(point)
          }
        })


      }
    }
  }, [])

  if (isOpen) {
    // eslint-disable-next-line no-undef
    easyPack.modalMap('easypack-map', { width: 1200, height: 600 })

    const selector = document.querySelector('#widget-modal .widget-modal__close')
    selector.addEventListener('click', function() {
      onClose()
    }, { once: true })
  }

  return (
    <div id='easypack-map'></div>
  )
}

InPostGeoWidget.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  afterClose: PropTypes.func,
  options: PropTypes.object
}

export default InPostGeoWidget

