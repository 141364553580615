import React from 'react'
import ReactDOM from 'react-dom'

import Routes from './routes'
import App from './App'
import { Auth } from './utils/auth'
import './utils/i18n'

import './scss/index.scss'
import 'focus-visible/dist/focus-visible'

ReactDOM.render(
  <React.StrictMode>
    <App>
      <Auth>
        <Routes />
      </Auth>
    </App>
  </React.StrictMode>
  ,
  document.getElementById('root')
)
