import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Heading, Tabs, TabList, Tab, TabPanels, TabPanel, useBreakpointValue } from '@chakra-ui/react'

import Translate from '../../components/Translate'
import DataForm from './user-settings/DataForm'
import LearnForm from './user-settings/LearnForm'
import SecurityForm from './user-settings/SecurityForm'
import AddressesSection from './user-settings/AddressesSection'
import Loader from '../../components/Loader'

const SettingsHeader = ({ children }) => (
  <Heading as='h4' size='md' mb={6}>
    { children }
  </Heading>
)

function UserSettings() {

  const [loadedDataForm, setLoadedDataForm] = useState(false)
  const [loadedSecurityForm, setLoadedSecurityForm] = useState(false)
  const [loadedAddresses, setLoadedAddresses] = useState(false)

  const handleLoadedDataForm = () => { setLoadedDataForm(true) }
  const handleLoadedSecurityForm = () => { setLoadedSecurityForm(true) }
  const handleLoadedAddresses = () => { setLoadedAddresses(true) }

  const tabSize = useBreakpointValue({ base: 'sm', sm: 'md' })

  return (
    <Loader loaded={ loadedDataForm && loadedSecurityForm && loadedAddresses }>
      <Box>
        <Heading as='h3' size='lg' mb={4}>
          <Translate>Settings</Translate>
        </Heading>
        <Tabs size={tabSize}>
          <TabList>
            <Tab>
              <Translate>Learning</Translate>
            </Tab>
            <Tab>
              <Translate>Data</Translate>
            </Tab>
            <Tab>
              <Translate>E-mail &amp; password</Translate>
            </Tab>
            <Tab>
              <Translate>Addresses</Translate>
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <SettingsHeader>
                <Translate>Learning</Translate>
              </SettingsHeader>
              <LearnForm />
            </TabPanel>
            <TabPanel>
              <SettingsHeader>
                <Translate>Your data</Translate>
              </SettingsHeader>
              <DataForm onLoad={handleLoadedDataForm}/>
            </TabPanel>
            <TabPanel>
              <SettingsHeader>
                <Translate>E-mail &amp; password</Translate>
              </SettingsHeader>
              <SecurityForm onLoad={handleLoadedSecurityForm}/>
            </TabPanel>
            <TabPanel>
              <SettingsHeader>
                <Translate>Addresses</Translate>
              </SettingsHeader>
              <AddressesSection onLoad={handleLoadedAddresses} isSelectable={false} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Loader>
  )
}

export default UserSettings

SettingsHeader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string
  ])
}
