import React, { useState, useEffect, Suspense } from 'react'
import { Box, Heading, SimpleGrid, Text, Select, Flex } from '@chakra-ui/react'
import randomstring from 'randomstring'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { useApiFetch } from '../../utils/api'
import Translate from '../../components/Translate'
import Loader from '../../components/Loader'
import Set from '../../components/sets/Set'

function StoreWaitingForSuspense() {

  const { t } = useTranslation()

  const [products, setProducts] = useState(false)
  const [type, setType] = useState('/roadmaps')

  const api = useApiFetch()

  useEffect(() => {
    setProducts(false)

    api('/store/products' + type)
      .then(res => {
        setProducts(res)
      })
      .catch(() => {
        setProducts([])
      })
  }, [type])

  const viewChange = (e) => {
    const { value } = e.target

    setType(`/${value}`)
  }

  const productStorage = products !== false ? products : new Array(6)
    .fill({})
    .map(() => ({ product_id: randomstring.generate({ length: 8 }), background: null }))

  return (
    <Box>
      <Heading mb={5}>
        <Translate>Store</Translate>
      </Heading>
      <Flex mb={5} w={'40%'} alignItems='center' justifyContent='space-between'>
        <Box mr={2} display='inline'><Translate>View</Translate>: </Box>
        <Select variant='filled' defaultValue='roadmaps' onChange={viewChange}>
          <option value="roadmaps">{ t('Roadmaps') }</option>
          <option value="sets">{ t('Sets') }</option>
        </Select>
      </Flex>
      {
        productStorage.length ?
          <SimpleGrid columns={{ base: 1, sm: 2, lg: 3 }} spacing={10}>
            {
              productStorage.map(set => (
                <Set key={'pns-'+ set.product_id} data={set} loaded={!!products} toBuy={true} />
              ))
            }
          </SimpleGrid>
          : <Text>
            <Translate>There aren&apos; any sets available in the store</Translate>.
          </Text>
      }
    </Box>
  )
}

export default function Store(props) {
  return (
    <Suspense fallback={<Loader loaded={false} />}>
      <StoreWaitingForSuspense { ...props } />
    </Suspense>
  )
}

Store.propTypes = {
  props: PropTypes.object
}
