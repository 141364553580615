import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Icon, InputGroup } from '@chakra-ui/react'
import Translate from '../Translate'
import { AttachmentIcon } from '@chakra-ui/icons'

const FileUploadInput = ({ accept, multiple, onChange: handleChange, align = 'left' }) => {

  const [isSubmitting, setIsSubmitting] = useState(false)
  const inputRef = useRef(null)

  const handleClick = () => inputRef.current.click()

  const onChange = (e) => {
    const { files } = e.target

    setIsSubmitting(true)

    const imagesFinish = new Array(files.length).fill(false)
    const newImages = []

    const event = new Event('finish_loading_images')

    Array.prototype.forEach.call(files, (file, ix) => {

      const reader = new FileReader()

      reader.readAsDataURL(file)
      reader.onloadend = (e) => {
        newImages.push({ file, uri: e.target.result })
        imagesFinish[ix] = true

        if (!imagesFinish.includes(false)) inputRef.current.dispatchEvent(event)
      }
    })

    inputRef.current.addEventListener('finish_loading_images', () => {
      handleChange(newImages)
      setIsSubmitting(false)
    })
  }

  const parseFlex = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end',
  }

  return (
    <InputGroup onClick={handleClick} display='flex' justifyContent={parseFlex[align]}>
      <input
        type={'file'}
        multiple={multiple || false}
        hidden
        accept={accept}
        ref={inputRef}
        onChange={onChange}
      />
      <Button isLoading={isSubmitting} leftIcon={<Icon as={AttachmentIcon} />}>
        <Translate>Select files</Translate>
      </Button>
    </InputGroup>
  )
}

FileUploadInput.propTypes = {
  accept: PropTypes.string,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  align: PropTypes.oneOf(['left', 'center', 'right'])
}

export default FileUploadInput

// const App = () => {
//   const { register, handleSubmit, formState: { errors } } = useForm<FormValues>()
//   const onSubmit = handleSubmit((data) => console.log('On Submit: ', data))

//   const validateFiles = (value: FileList) => {
//     if (value.length < 1) {
//       return 'Files is required'
//     }
//     for (const file of Array.from(value)) {
//       const fsMb = file.size / (1024 * 1024)
//       const MAX_FILE_SIZE = 10
//       if (fsMb > MAX_FILE_SIZE) {
//         return 'Max file size 10mb'
//       }
//     }
//     return true
//   }

//   return (
//     <>
//       <form onSubmit={onSubmit}>
//         <FormControl isInvalid={!!errors.file_} isRequired>
//           <FormLabel>{'File input'}</FormLabel>

//           <FileUpload
//             accept={'image/*'}
//             multiple
//             register={register('file_', { validate: validateFiles })}
//           >
//             <Button leftIcon={<Icon as={FiFile} />}>
//               Upload
//             </Button>
//           </FileUpload>

//           <FormErrorMessage>
//             {errors.file_ && errors?.file_.message}
//           </FormErrorMessage>
//         </FormControl>

//         <button>Submit</button>
//       </form>
//     </>
//   )
// }

// export default App
