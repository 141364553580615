import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FormControl, FormLabel, FormHelperText, Input, InputGroup, useColorModeValue } from '@chakra-ui/react'

function TextInput({ name, label, helper, value, error, type, innerLabel, inputLeft, inputRight, onInput: passOnInput }) {
  const [state, setState] = useState({
    value: value || '',
    errorPresented: false,
  })

  const inputBg = useColorModeValue('white', 'gray.700')

  useEffect(() => {
    if (state.errorPresented) setState({ ...state, errorPresented: false })
  }, [error, state])

  const handleInput = (e) => {
    setState({ ...state, value: e.target.value })
    if (error && !state.errorPresented) setState({ ...state, errorPresented: true })
    if (passOnInput) passOnInput(e)
  }

  return (
    <FormControl id={name} isInvalid={ error && !state.errorPresented }>
      { !innerLabel ? <FormLabel>{ label }</FormLabel> : null }
      <InputGroup>
        { inputLeft || null }
        <Input bg={inputBg} type={ type } onInput={handleInput} placeholder={ innerLabel ? label : null }/>
        { inputRight || null }
      </InputGroup>
      { helper ? <FormHelperText>{ helper }</FormHelperText> : null }
      { error && !state.errorPresented ? <FormHelperText color='red.400'>{ error }</FormHelperText> : null }
    </FormControl>
  )
}

TextInput.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  helper: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  inputLeft: PropTypes.element,
  inputRight: PropTypes.element,
  innerLabel: PropTypes.bool,
  onInput: PropTypes.func
}

export default TextInput
