/**
 * Converts URLSearchParams Object into object
 *
 * @param {URLSearchParams} urlSearchParams
 * @return {object}
 */
export function uspToObject(urlSearchParams) {

  let object = {}

  for(let entry of urlSearchParams.entries()) {
    object[entry[0]] = entry[1]
  }

  return object
}
