import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Text, AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogOverlay, AlertDialogHeader, AlertDialogFooter, Button } from '@chakra-ui/react'
import Translate from '../Translate'
import { useApiFetch } from '../../utils/api'

function ArchiveDialog({ data, object, type = 'archive', forceRenew }) {
  const [isOpen, setIsOpen] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const onClose = () => setIsOpen(false)
  const cancelRef = useRef()
  const api = useApiFetch()

  useEffect(() => {
    if (data !== null) setIsOpen(true)
  }, [data])

  const { id, title } = data || { id: 0, title: 'unknown' }

  const handleClick = () => {
    setIsSubmitting(true)

    api(`/admin/${object}s/${id}` + (type === 'archive' ? '/archive' : ''), {
      method: type === 'archive' ? 'PUT' : 'DELETE',
    })
      .then(() => {
        forceRenew()
        setIsSubmitting(false)
        onClose()
      })
      .catch(() => {
        setIsSubmitting(false)
      })
  }

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        autoFocus
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {
                type === 'delete' ? <Translate>Delete {object}</Translate> : <Translate>Archive {object}</Translate>
              }
            </AlertDialogHeader>

            <AlertDialogBody>
              <Text>
                {
                  type === 'delete'
                    ? <Translate options={{ title }}>{`Are you sure you want to delete the {{ title }} ${object}?`}</Translate>
                    : <Translate options={{ title }}>{`Are you sure you want to archive the {{ title }} ${object}?`}</Translate>
                }
              </Text>
              <Text color={type === 'delete' ? 'red' : 'inherit'}>
                {
                  type === 'delete'
                    ? <Translate>This operation is irreversible.</Translate>
                    : <Translate>This operation is reversible.</Translate>
                }
              </Text>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                <Translate>Cancel</Translate>
              </Button>
              {
                type === 'delete'
                  ? <Button onClick={handleClick} ml={3} isLoading={isSubmitting} variant='brand-ghost'>
                    <Translate>Delete</Translate>
                  </Button>
                  : <Button onClick={handleClick} ml={3} isLoading={isSubmitting} variant='brand-ghost'>
                    <Translate>Archive</Translate>
                  </Button>
              }
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

ArchiveDialog.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string
  }),
  object: PropTypes.oneOf(['set', 'roadmap']),
  type: PropTypes.oneOf(['archive', 'delete']),
  forceRenew: PropTypes.func
}

export default ArchiveDialog
