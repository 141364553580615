import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import EditorJs from 'react-editor-js'
import { HStack, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure } from '@chakra-ui/react'
import Translate from '../Translate'
import TOOLS from '../../utils/editorjsTools'

function DescriptionDialog({ initialValue, disclosure, change }) {
  const [value, setValue] = useState(initialValue || [])

  const data = {
    version: '2.22.2',
    timestamp: Math.floor(Date.now() / 1000),
    blocks: typeof value === 'string' ? JSON.parse(value) : value
  }

  const ownDisclosure = useDisclosure()

  const { isOpen, onClose, handleChange } = disclosure || ownDisclosure

  const cancelRef = useRef()

  const handleClick = () => {
    if (handleChange) handleChange(JSON.stringify(value))
    if (change) change(JSON.stringify(value))

    onClose()
  }

  const onEditorChange = (_api, { blocks }) => {
    setValue(blocks)
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior='inside'
      size='3xl'
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="lg" fontWeight="bold">
          <Translate>Change description</Translate>
        </ModalHeader>

        <ModalBody px={20}>
          <EditorJs data={data} onChange={onEditorChange} tools={TOOLS} minHeight={0} />
        </ModalBody>

        <ModalFooter>
          <HStack spacing={2}>
            <Button ref={cancelRef} onClick={onClose} ml={3} variant='brand-ghost'>
              <Translate>Cancel</Translate>
            </Button>
            <Button onClick={handleClick}>
              <Translate>Change</Translate>
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

DescriptionDialog.propTypes = {
  initialValue: PropTypes.string,
  disclosure: PropTypes.shape({
    isOpen: PropTypes.bool,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    handleChange: PropTypes.func
  }),
  open: PropTypes.func,
  change: PropTypes.func
}

export default DescriptionDialog
