import React from 'react'
import PropTypes from 'prop-types'
import { Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, useBreakpointValue } from '@chakra-ui/react'
import Translate from '../../../components/Translate'

function AddressFormModalWrapper({ children, asModal, onSubmit, isOpen, onOpen, onClose }) {

  const handleSubmit = () => onSubmit()

  const openButtonWidth = useBreakpointValue({ base: '100%', md: 'initial' })

  return (
    asModal ?
      <>
        <Button onClick={onOpen} w={openButtonWidth}>
          <Translate>Add new address</Translate>
        </Button>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <Translate>Add new address</Translate>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              { children }
            </ModalBody>

            <ModalFooter>
              <Button mr={3} onClick={onClose} variant='brand-ghost'>
                <Translate>Close</Translate>
              </Button>
              <Button variant="brand-solid" onClick={handleSubmit}>
                <Translate>Add</Translate>
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
      : children
  )
}

AddressFormModalWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  asModal: PropTypes.bool,
  onSubmit: PropTypes.func,
  isOpen: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func
}

export default AddressFormModalWrapper

