import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, SimpleGrid, useBreakpointValue, Text, VStack } from '@chakra-ui/react'
import { useApiFetch } from '../../../utils/api'
import AddressForm from './AddressForm'
import Translate from '../../../components/Translate'
import AddressInput from '../../../components/inputs/AddressInput'

function AddressesSection({ onLoad: handleOnLoad, onChange, isSelectable, selected, inputPrefix }) {

  const [addresses, setAddresses] = useState([])
  const [selectedAddress, setSelectedAddress] = useState(isSelectable && selected ? selected : null)
  const [refresh, setRefresh] = useState(true)
  const api = useApiFetch()
  const gridCols = useBreakpointValue({ base: 1, md: 2, lg: 3 })

  useEffect(() => {
    if (refresh) {
      api('/client/address')
        .then((response) => {
          setAddresses(response)
        })
        .finally(() => {
          setRefresh(false)
          handleOnLoad ? handleOnLoad() : null
        })
        .catch(() => {})
    }
  }, [refresh])

  useEffect(() => onChange ? onChange(selectedAddress) : null, [selectedAddress])

  const handleRefresh = () => setRefresh(true)
  const handleClick = (id) => isSelectable ? setSelectedAddress(id) : null

  return (
    <VStack>
      <Box w='100%'>
        <AddressForm asModal refresh={handleRefresh}/>
      </Box>
      {
        addresses.length ?
          <SimpleGrid columns={gridCols} spacing={4} w='100%'>
            {
              addresses.map((data) => (
                <AddressInput key={'addr-'+ data.id} data={data} input={ isSelectable ? { name: (inputPrefix ? inputPrefix + '-' : '' ) + 'address', value: data.id } : null } selected={selectedAddress === data.id} onClick={handleClick} refresh={handleRefresh} />
              ))
            }
          </SimpleGrid>
          : <Text>
            <Translate>No addresses added.</Translate>
          </Text>
      }
    </VStack>
  )
}

AddressesSection.propTypes = {
  onLoad: PropTypes.func,
  isSelectable: PropTypes.bool,
  selected: PropTypes.number,
  inputPrefix: PropTypes.string,
  onChange: PropTypes.func
}

export default AddressesSection
