import React from 'react'
import PropTypes from 'prop-types'
import { Box, Heading, useColorModeValue } from '@chakra-ui/react'

function DashboardMenuDivider({ children }) {

  const color = useColorModeValue('gray.500', 'gray.500')

  return (
    <Box>
      <Heading my={3} fontSize='md' color={color} fontWeight='300' letterSpacing={0.2}>{ children }</Heading>
    </Box>
  )
}

DashboardMenuDivider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string
  ])
}

export default DashboardMenuDivider

