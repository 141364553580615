import React from 'react'
import PropTypes from 'prop-types'

import { useColorModeValue } from '@chakra-ui/react'

import '../scss/components/Logo.scss'

function Logo({ src, style, color, ...props}) {

  const chakraColor = useColorModeValue('dark', 'light')

  return (
    <div style={{ ...style, ...{ backgroundImage: `url('/img/logo/${src}-${color ? color : chakraColor}.png')`} }} { ...props } className={'logo-component'+ (src === 'logo-50' ? ' logo-component--s-50' : '')}></div>
  )
}

Logo.propTypes = {
  src: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
}

Logo.defaultProps = {
  src: 'logo-100'
}

export default Logo
