import React from 'react'
import PropTypes from 'prop-types'
import { Text, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, Stack, Box, Stat, StatLabel, StatNumber, HStack, Flex, Spacer, StatHelpText, Badge, Icon } from '@chakra-ui/react'
import { FaTag } from 'react-icons/fa'
// import { StarIcon } from '@chakra-ui/icons'

import ParseEditorJs from '../../utils/ParseEditorJs'
import Translate from '../Translate'
import AddToBasketButton from '../buttons/AddToBasketButton'

function SetTrailer({ isOpen, onClose, data }) {

  const { product_id, image, title, description, added_date } = data

  return (
    <Modal isOpen={isOpen} onClose={onClose} scrollBehavior='outside'>
      <ModalOverlay />
      <ModalContent maxW={1000} mx={6}>
        <ModalHeader bg={`linear-gradient(0deg, #000000a6 0%, rgba(255, 255, 255, 0) 100%), url(${image}) center/cover no-repeat`} minH={300} fontSize={30} color='#FFF' display='flex' alignItems='flex-end'>
          <Box>
            { title }
            { (added_date + (3600 * 24 * 30)) > (Date.now() / 1000) ? <Badge ml={2} variant="subtle" colorScheme="green"><Translate>NEW</Translate></Badge> : null }
            { data.price && data.price.promotion ? <Badge ml={2} variant="solid" colorScheme="red"><Translate>PROMOTION!</Translate></Badge> : null }
          </Box>
        </ModalHeader>
        <ModalBody py={4}>
          <Stack direction={{ base: 'column', md: 'row' }} spacing={8}>
            <Box w={{ base: '100%', md: '60%' }}>
              <ParseEditorJs content={description} />
            </Box>
            <Box w={{ base: '100%', md: '40%' }}>
              <Flex>
                {/* <Stat>
                  <StatLabel><Translate>Ratings</Translate></StatLabel>
                  <StatNumber display='flex' alignItems='center'>
                    <HStack spacing={1}>
                      <StarIcon w={4} h={4} color='yellow.500'/>
                      <Box>5.00</Box>
                    </HStack>
                  </StatNumber>
                </Stat> */}
                <Spacer />
                {
                  data.price && data.price.brutto ?
                    <Stat pos='relative'>
                      <StatLabel {...(data.price.promotion ? { color: 'red.500' } : {} )}>
                        <Translate>{data.price.promotion ? 'Promotion price' : 'Price' }</Translate>
                      </StatLabel>
                      <StatNumber> { data.price.brutto.formatted } </StatNumber>
                      {
                        data.price.promotion ?
                          <HStack>
                            {
                              data.price.promotion ?
                                <StatHelpText textDecor='line-through' color='red.300' mb={0}>{ data.price.promotion.oldprice.formatted }</StatHelpText>
                                : null
                            }
                            <Badge ml={2} variant="solid" colorScheme="red">
                              <HStack spacing={1}>
                                <Icon as={FaTag} />
                                <Text>-{ data.price.promotion.percentage }%</Text>
                              </HStack>
                            </Badge>
                          </HStack>
                          : null
                      }
                      <StatHelpText>
                        <Translate options={{ tax: data.price.tax }}>{'Includes {{tax}}% VAT'}</Translate>
                      </StatHelpText>
                    </Stat> : null
                }
              </Flex>
            </Box>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} variant="ghost" onClick={onClose}>
            <Translate>Close</Translate>
          </Button>
          { data.price && data.price.brutto ? <AddToBasketButton item={product_id} /> : null }
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

SetTrailer.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  data: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    product_id: PropTypes.number,
    level: PropTypes.number,
    order: PropTypes.number,
    set: PropTypes.number,
    progress: PropTypes.number,
    title: PropTypes.string,
    image: PropTypes.string,
    bought: PropTypes.bool,
    description: PropTypes.string,
    tax: PropTypes.number,
    added_date: PropTypes.number,
    max_per_basket: PropTypes.number,
    min_per_basket: PropTypes.number,
    price: PropTypes.shape({
      brutto: PropTypes.shape({
        formatted: PropTypes.string,
        bare: PropTypes.number
      }),
      tax: PropTypes.number,
      promotion: PropTypes.shape({
        percentage: PropTypes.number,
        oldprice: PropTypes.shape({
          formatted: PropTypes.string,
          bare: PropTypes.number
        }),
        to: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([ null ])])
      })
    })
  })
}

export default SetTrailer

