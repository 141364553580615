import React from 'react'
import PropTypes from 'prop-types'
import edjsHTML from 'editorjs-html'
import VideoJS from '../components/sets/VideoPlayer'

function ParseEditorJs({ content }) {

  const data = {
    version: '2.22.2',
    timestamp: Math.floor(Date.now() / 1000),
    blocks: JSON.parse(content)
  }

  const edjsParser = edjsHTML({ video: ({ data: { url } }) => (url) })
  const parsed = edjsParser.parse(data).map((html, ix) => ({
    html,
    id: data.blocks[ix].id,
    type: data.blocks[ix].type,
    completed: data.blocks[ix].completed
  }))

  return (
    parsed.map(({ html: content, id, type }) => (
      type === 'video'
        ? <VideoJS options={{
          autoplay: false,
          controls: true,
          responsive: true,
          fluid: true,
          sources: {
            src: content,
            type: 'application/x-mpegURL',
          },
        }} id={`lc__${id}`} key={`set-comp-${id}`} />
        : <div key={`set-comp-${id}`} id={`lc__${id}`} className={`lc lc__${type}`} dangerouslySetInnerHTML={{ __html: content }} />
    ))
  )
}

ParseEditorJs.propTypes = {
  content: PropTypes.string
}

export default ParseEditorJs

