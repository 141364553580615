import React from 'react'
import PropTypes from 'prop-types'
import { CheckIcon } from '@chakra-ui/icons'
import { Box, Heading, HStack, Image, VStack, useColorModeValue, Icon } from '@chakra-ui/react'
import { MdAccountBalance } from 'react-icons/md'
import Translate from '../Translate'

function PaymentMethodInput({ data: { id, name, image }, selected, onClick }) {
  const bgColor = useColorModeValue(selected ? 'brand.400' : 'gray.200', selected ? 'gray.600' : 'gray.800')
  const bgHoverColor = useColorModeValue(selected ? 'brand.400' : 'gray.100', selected ? 'gray.600' : 'gray.900')
  const fontColor = useColorModeValue(selected ? 'white' : 'gray.800', selected ? 'white' : 'white')

  const handleClick = () => onClick && onClick(id)

  return (
    <Box shadow='sm' position='relative' p={4} borderRadius='15px' transition='.2s' cursor='pointer' w='100%' bg={bgColor} _hover={{ bg: bgHoverColor }} color={fontColor} onClick={handleClick}>
      <HStack>
        { selected ? <CheckIcon color={fontColor} w={5} h={5} /> : null }
        { image ? <Image src={image} h={25} maxW={50} objectFit='contain' /> : <Icon fontSize='2em' w={50} as={MdAccountBalance} /> }
        <VStack pl={2}>
          <Heading as='h5' size='md' w={'100%'}>
            <Translate>{ name }</Translate>
          </Heading>
        </VStack>
      </HStack>
    </Box>
  )
}

PaymentMethodInput.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    image: PropTypes.string,
  }),
  onClick: PropTypes.func,
  selected: PropTypes.bool
}

export default PaymentMethodInput
