import React, { useEffect, useState } from 'react'
import { Box, Heading, VStack, Spinner, Text, UnorderedList, ListItem, Button } from '@chakra-ui/react'
import { useParams, useHistory } from 'react-router-dom'
import { useApiFetch } from '../../../utils/api'
import Loader from '../../../components/Loader'
import Translate from '../../../components/Translate'
import OrderPaymentCheckStatus from '../../../components/orders/OrderPaymentCheckStatus'

function OrderPaymentCheck() {

  const { uuid } = useParams()
  const history = useHistory()

  const [payment, setPayment] = useState(null)
  const [payuStatus, setPayuStatus] = useState({ code: 'CREATED' })

  const api = useApiFetch()

  useEffect(() => {
    var payuCheckInterval

    const payuHandling = () => {
      api('/payments/'+ uuid).then(([{ statuses: [ lastStatus ] }]) => {
        setPayuStatus(lastStatus)

        if (lastStatus && (lastStatus.code !== 'PENDING' && lastStatus.code !== 'CREATED')) clearInterval(payuCheckInterval)
      })
        .catch(() => {})
    }

    api('/payments/'+ uuid).then(([ response ]) => {
      setPayment(response)

      if (response.shortname === 'payu') {
        payuCheckInterval = setInterval(payuHandling, 2000)
      }
    })
      .catch(() => {})

    return () => {
      clearInterval(payuCheckInterval)
    }
  }, [])

  const handleTransactionRetry = () => {
    history.replace(`/platform/orders/${uuid}/payment`)
  }

  const handleTransactionFinish = () => {
    history.replace('/platform/roadmap')
  }

  return (
    <Loader loaded={payment !== null}>
      <Box p={6}>
        <VStack spacing={4}>
          {
            payment
              ? (
                payment.shortname === 'payu' && payuStatus
                  ? (
                    <VStack spacing={4}>
                      <OrderPaymentCheckStatus status={payuStatus.code} shortname={payment.shortname} />
                      {
                        payuStatus.code === 'CANCELED'
                          ? (
                            <Button variant='brand-solid' onClick={handleTransactionRetry}>
                              <Translate>Retry transaction</Translate>
                            </Button>
                          )
                          : (
                            payuStatus.code === 'COMPLETED'
                              ? (
                                <Button variant='brand-solid' onClick={handleTransactionFinish}>
                                  <Translate>Go to roadmaps</Translate>
                                </Button>
                              )
                              :null
                          )
                      }
                    </VStack>
                  ) : (
                    payment.shortname ===  'transfer'
                      ? (
                        <VStack spacing={4}>
                          <OrderPaymentCheckStatus status={payment.statuses[0].code} shortname={payment.shortname} />
                          <Text>
                            <Translate>Make a bank transfer to the given data</Translate>:
                          </Text>
                          <UnorderedList>
                            <ListItem>
                              <Text fontWeight='bold' display='inline-block'><Translate>Account number</Translate>:</Text> { payment.details.account.number }
                            </ListItem>
                            <ListItem>
                              <Text fontWeight='bold' display='inline-block'><Translate>Sum to transfer</Translate>:</Text> { payment.sum.formatted }
                            </ListItem>
                            <ListItem>
                              <Text fontWeight='bold' display='inline-block'><Translate>With title</Translate>:</Text> { payment.details.title }
                            </ListItem>
                            <ListItem>
                              <Text fontWeight='bold' display='inline-block'><Translate>Address</Translate>:</Text> { payment.details.account.name }, { payment.details.account.address }
                            </ListItem>
                            <ListItem>
                              <Text fontWeight='bold' display='inline-block'><Translate>Bank</Translate>:</Text> { payment.details.account.bank }
                            </ListItem>
                          </UnorderedList>
                        </VStack>
                      )
                      : null
                  )
              ) : (
                <Box>
                  <Spinner size='lg' color='brand' />
                  <Heading as='h2' size='lg'>
                    <Translate>Checking your payment status...</Translate>
                  </Heading>
                </Box>
              )
          }
        </VStack>
      </Box>
    </Loader>
  )
}

export default OrderPaymentCheck
