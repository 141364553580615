import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box, FormControl, FormLabel, InputGroup, InputLeftElement,Icon, Input, FormErrorMessage, Stack, Heading, Button, useToast } from '@chakra-ui/react'
import { MdLock, MdMail } from 'react-icons/md'
import validator from 'validator'

import Translate from '../../../components/Translate'
import { useApiFetch } from '../../../utils/api'

function SecurityForm({ onLoad: sendIsLoaded }) {

  const api = useApiFetch()
  const toast = useToast()

  const [data, setData] = useState({
    email: '',
    password: '',
    repeatedPassword: '',
    actualPassword: ''
  })
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [errors, setErrors] = useState({})

  useEffect(() => {
    sendIsLoaded()
  }, [])

  const handleInput = (e, field) => {
    setData({ ...data, [field]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setErrors({})
    setIsSubmitting(true)

    let currentErrors = {}

    const { email, password, repeatedPassword, actualPassword } = data

    if (!actualPassword) {
      currentErrors = { ...currentErrors, actualPassword: <Translate>This field can&apos;t be empty</Translate> }
    }

    if (password || repeatedPassword) {
      if (!password) currentErrors = { ...currentErrors, password: <Translate>This field can&apos;t be empty</Translate> }
      if (!repeatedPassword) currentErrors = { ...currentErrors, repeatedPassword: <Translate>This field can&apos;t be empty</Translate> }

      if (password && repeatedPassword) {
        if (!validator.isStrongPassword(password, { minSymbols: 0 })) currentErrors = { ...currentErrors, password: <Translate>Password requires min. 8 chars, 1 uppercase letter and 1 number</Translate> }
        if (password !== repeatedPassword) currentErrors = { ...currentErrors, repeatedPassword: <Translate>Passwords are not the same</Translate> }
      }
    }

    if (email && !validator.isEmail(email)) {
      currentErrors = { ...currentErrors, email: <Translate>Email is in incorrect format</Translate> }
    }

    if (Object.keys(currentErrors).length) {
      setErrors(currentErrors)
      setIsSubmitting(false)
    }
    else {
      api('/user', {
        method: 'PUT',
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify({
          actualPassword,
          ...(password ? { password, repeatedPassword } : {}),
          ...(email ? { email } : {})
        })
      })
        .then( ({ message}) => {
          toast({
            title: <Translate>Changed</Translate>,
            ...(message ? { description: <Translate>{ message }</Translate> } : {} ),
            duration: 8000,
            isClosable: true,
            status: 'success'
          })
          setIsSubmitting(false)
        })
        .catch( async (res) => {

          try {
            const { message } = await res.json()

            toast({
              title: <Translate>Error occured</Translate>,
              description: <Translate>{ message }</Translate>,
              duration: 5000,
              isClosable: true,
              status: 'error'
            })
            setIsSubmitting(false)
          }
          catch(err) {
            toast({
              title: <Translate>Error occured</Translate>,
              description: <Translate>Internal API error</Translate>,
              duration: 5000,
              isClosable: true,
              status: 'error'
            })
            setIsSubmitting(false)
          }
        })

    }

  }

  return (
    <Box w={{ base: '100%', md: '60%' }}>
      <form method='POST' onSubmit={handleSubmit} >
        <Stack spacing={10}>
          <Box>
            <Stack direction={{ base: 'column', md: 'row' }} spacing={10}>
              <Box w={{ base: '100%', md: '50%' }}>
                <Heading as='h3' size='md' mb={4}><Translate>E-mail change</Translate></Heading>
                <FormControl isInvalid={errors.email}>
                  <FormLabel>
                    <Translate>E-mail</Translate>
                  </FormLabel>
                  <InputGroup>
                    <InputLeftElement size='lg' pointerEvents="none" color="gray.300" fontSize="1.2em">
                      <Icon as={MdMail} color='gray.300'/>
                    </InputLeftElement>
                    <Input type='email' name='email' onChange={e => handleInput(e, 'email')} autoComplete='new-email' />
                  </InputGroup>
                  <FormErrorMessage>{ errors.email }</FormErrorMessage>
                </FormControl>
              </Box>
              <Stack w={{ base: '100%', md: '50%' }}>
                <Heading as='h3' size='md' mb={4}><Translate>Password change</Translate></Heading>
                <FormControl isInvalid={errors.password}>
                  <FormLabel>
                    <Translate>Password</Translate>
                  </FormLabel>
                  <InputGroup>
                    <InputLeftElement size='lg' pointerEvents="none" color="gray.300" fontSize="1.2em">
                      <Icon as={MdLock} color='gray.300'/>
                    </InputLeftElement>
                    <Input type='password' name='password' onChange={e => handleInput(e, 'password')} autoComplete='new-password' />
                  </InputGroup>
                  <FormErrorMessage>{ errors.password }</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.repeatedPassword}>
                  <FormLabel>
                    <Translate>Repeat password</Translate>
                  </FormLabel>
                  <InputGroup>
                    <InputLeftElement size='lg' pointerEvents="none" color="gray.300" fontSize="1.2em">
                      <Icon as={MdLock} color='gray.300'/>
                    </InputLeftElement>
                    <Input type='password' name='repeatedPassword' onChange={e => handleInput(e, 'repeatedPassword')} autoComplete='off' />
                  </InputGroup>
                  <FormErrorMessage>{ errors.repeatedPassword }</FormErrorMessage>
                </FormControl>
              </Stack>
            </Stack>
          </Box>
          <Box>
            <FormControl isInvalid={errors.actualPassword}>
              <FormLabel>
                <Translate>Actual password</Translate>
              </FormLabel>
              <InputGroup>
                <InputLeftElement size='lg' pointerEvents="none" color="gray.300" fontSize="1.2em">
                  <Icon as={MdLock} color='gray.300'/>
                </InputLeftElement>
                <Input type='password' name='actualPassword' onChange={e => handleInput(e, 'actualPassword')} autoComplete='new-password' />
              </InputGroup>
              <FormErrorMessage>{ errors.actualPassword }</FormErrorMessage>
            </FormControl>
          </Box>
          <Box>
            <Button variant='brand-solid' type='submit' isLoading={isSubmitting}>
              <Translate>Change</Translate>
            </Button>
          </Box>
        </Stack>
      </form>
    </Box>
  )
}

SecurityForm.propTypes = {
  onLoad: PropTypes.func
}

export default SecurityForm

