import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { FormErrorMessage, Select, Heading, Text, Box, FormControl, FormLabel, Input, Stack, VStack, Image, Flex, Textarea, Button, IconButton, Spacer, createStandaloneToast, useDisclosure, FormHelperText } from '@chakra-ui/react'
import { AddIcon, ArrowDownIcon, ArrowUpIcon, CloseIcon } from '@chakra-ui/icons'
import { arrayMoveImmutable } from 'array-move'
import isUrl from 'is-url'
import Translate from '../Translate'
import { useApiFetch } from '../../utils/api'
import Loader from '../Loader'
import FileUploadInput from '../inputs/FileUploadInput'
import DescriptionDialog from './DescriptionDialog'

function RoadmapEdit({ id, changeId }) {
  const initialErrorsObj = {
    title: '',
    description: '',
    short_description: '',
    image: '',
    sets: ''
  }

  const [roadmap, setRoadmap] = useState(null)
  const [roadmapErrors, setRoadmapErrors] = useState(initialErrorsObj)
  const [loaded, setLoaded] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [setsStorage, setSetsStorage] = useState(null)
  const [setsSelect, setSetsSelect] = useState(null)
  const selectRef = useRef(null)
  const api = useApiFetch()
  const toast = createStandaloneToast()

  useEffect(() => {
    setLoaded(false)
    setRoadmapErrors(initialErrorsObj)

    if (typeof id === 'number') {
      api('/admin/roadmaps/'+ id)
        .then((response) => {
          setRoadmap({ ...response, sets: response.sets.map((set) => _.find(setsStorage, { id: set }) ) })
          setLoaded(true)
        })
        .catch(() => {})
    }
    else if (typeof id === 'string') {
      setRoadmap({
        title: '',
        description: '',
        short_description: '',
        image: {},
        sets: []
      })
      setLoaded(true)
    }
    else if (id === null) {
      setRoadmap(null)
      setLoaded(true)
    }
  }, [id])

  useEffect(() => {
    api('/admin/sets')
      .then((response) => {
        setSetsStorage(response.filter(({ archived, active }) => !archived && active))
        setSetsSelect(0)
      })
      .catch(() => {})
  }, [])

  useEffect(() => {
    setRoadmapErrors(initialErrorsObj)
  }, [roadmap])

  const handleSubmit = () => {
    setIsSubmitting(true)
    setRoadmapErrors(initialErrorsObj)

    api('/admin/roadmaps'+ (typeof id === 'number' ? '/'+ id : ''), {
      method: typeof id === 'number' ? 'PUT' : 'POST',
      body: JSON.stringify(_.omit({
        ...roadmap,
        sets: roadmap.sets.map(({ id }) => (id))
      }, ['image'])),
      headers: {
        'Content-type': 'application/json'
      }
    })
      .then((response) => new Promise((resolve) => {
        if (roadmap.image.file && !isUrl(roadmap.image)) {

          const body = new FormData()
          body.append('image', roadmap.image.file)

          api('/upload/roadmap-image', {
            method: 'POST',
            body
          }).then(({ file: { url } }) => {

            api('/admin/roadmaps/'+ response.id + '/image', {
              method: 'PUT',
              headers: {
                'Content-type': 'application/json'
              },
              body: JSON.stringify({ image: url })
            })
              .then(() => resolve(response))
              .catch(() => resolve(response))
          })
            .catch(() => {
              toast({
                id: 'img-send-error',
                title: <Translate>Error uploading image</Translate>,
                status: 'error',
                duration: 5000,
                isClosable: true
              })

              resolve(response)
            })
        }
        else {
          resolve(response)
        }
      }))
      .then((response) => {
        setIsSubmitting(false)

        if (!toast.isActive('create-success')) {
          toast({
            id: 'create-success',
            title: <Translate>{typeof id === 'number' ? 'Updated!' : 'Created!'}</Translate>,
            status: 'success',
            duration: 5000,
            isClosable: true
          })
        }

        if (typeof id === 'string') changeId(response.id)
      })
      .catch(async (response) => {
        setIsSubmitting(false)
        const json = await response.json()

        setRoadmapErrors(json.errors)

        if (!toast.isActive('response-error')) {
          const { status, statusText } = response
          const message = ''
          toast({
            id: 'response-error',
            title: <Translate>{status === 400 ? 'Fix all problems' : 'Unexpected error'}</Translate>,
            description: <Translate>{status === 400 ? message : statusText}</Translate>,
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
        }
      })
  }

  const handleSetAdd = () => {
    const newSet = setsStorage[setsSelect]

    setRoadmap({ ...roadmap, sets: [...roadmap.sets, { ...newSet } ]})
  }

  const handleMoveUp = (ix) => {
    const sets = ix !== 0 ? arrayMoveImmutable(roadmap.sets, ix, ix - 1) : roadmap.sets

    setRoadmap({ ...roadmap, sets })
  }

  const handleMoveDown = (ix) => {
    const sets = ix + 1 !== roadmap.sets.length ? arrayMoveImmutable(roadmap.sets, ix, ix + 1) : roadmap.sets

    setRoadmap({ ...roadmap, sets })
  }

  const handleRemove = (ix) => {
    const arr = roadmap.sets
    arr.splice(ix, 1)

    setRoadmap({ ...roadmap, sets: arr })
  }

  const descriptionDisclosure = useDisclosure()

  return (
    roadmap && setsStorage
      ? <Loader loaded={loaded && setsStorage !== null}>
        <Box p={4}>
          <FormControl id="name" isInvalid={roadmapErrors.title}>
            <FormLabel>
              <Translate>Title</Translate>
            </FormLabel>
            <Input onChange={(e) => setRoadmap({ ...roadmap, title: e.target.value })} value={roadmap.title} />
            <FormErrorMessage><Translate>{ roadmapErrors.title }</Translate></FormErrorMessage>
          </FormControl>
          <Stack direction={{ base: 'column', lg: 'row' }} align="stretch" py={4} spacing={4}>
            <VStack w='100%' spacing={4}>

              <FormControl id="short_description" isInvalid={roadmapErrors.short_description}>
                <FormLabel>
                  <Translate>Short description</Translate>
                </FormLabel>
                <Textarea maxLength={300} onChange={(e) => setRoadmap({ ...roadmap, short_description: e.target.value })} value={roadmap.short_description} resize='vertical'/>
                <FormHelperText>{ roadmap.short_description.length }/300</FormHelperText>
                <FormErrorMessage><Translate>{ roadmapErrors.short_description }</Translate></FormErrorMessage>
              </FormControl>

              <FormControl id="description" isInvalid={roadmapErrors.description}>
                <FormLabel>
                  <Translate>Description</Translate>
                </FormLabel>
                <Button isLoading={isSubmitting !== false} colorScheme="brand" variant="brand-solid" mr={2} onClick={descriptionDisclosure.onOpen}>
                  <Translate>{ (roadmap.description ? 'Change' : 'Set') + ' description' }</Translate>
                </Button>
                <FormErrorMessage><Translate>{ roadmapErrors.description }</Translate></FormErrorMessage>
              </FormControl>

              <DescriptionDialog initialValue={roadmap.description} change={(description) => setRoadmap({ ...roadmap, description })} disclosure={descriptionDisclosure}/>

              <FormControl id="image" isInvalid={roadmapErrors.image}>
                <FormLabel>
                  <Translate>Background image</Translate>
                </FormLabel>
                <FileUploadInput
                  accept={'image/*'}
                  onChange={([image]) => setRoadmap({ ...roadmap, image })}
                />
                <Image src={typeof roadmap.image === 'string' ? roadmap.image : roadmap.image.uri} maxH={200} objectFit='contain' mt={4}/>
                <FormErrorMessage><Translate>{ roadmapErrors.image }</Translate></FormErrorMessage>
              </FormControl>
            </VStack>
            <Box w='100%'>
              <Stack spacing={4} direction='column'>
                <Heading as='h5' size='sm'>
                  <Translate>Sets assigned to roadmap</Translate>
                </Heading>
                <Stack direction='row' pb={2}>
                  <Select ref={selectRef} onChange={() => { setSetsSelect(selectRef.current.selectedIndex) }}>
                    { setsStorage.map((set, ix) => <option key={'rmset-'+ set.id+ '-'+ ix} value={set.id}>{ set.title }</option>) }
                  </Select>
                  <IconButton icon={<AddIcon />} variant='brand-ghost' onClick={handleSetAdd} />
                </Stack>
                <Text color='red.500' fontSize='sm'><Translate>{ roadmapErrors.sets }</Translate></Text>
                {
                  roadmap.sets ?
                    roadmap.sets.length ?
                      roadmap.sets.map((set, ix) => (
                        <Flex key={'rmsetpos-'+ set.id +'-'+ix} px={4} py={2} borderColor='gray.200' borderStyle='solid' borderWidth='.4px' borderRadius={4}>
                          <Text display='flex' alignItems='center'>{ set.title }</Text>
                          <Spacer />
                          <Stack direction='row' spacing={2}>
                            <IconButton isDisabled={ix + 1 === roadmap.sets.length} icon={<ArrowDownIcon />} onClick={() => handleMoveDown(ix)} size='sm' variant='brand-ghost' />
                            <IconButton isDisabled={ix === 0} icon={<ArrowUpIcon />} onClick={() => handleMoveUp(ix)} size='sm' variant='brand-ghost' />
                            <IconButton icon={<CloseIcon boxSize={2.5}/>} size='sm' variant='brand-ghost' onClick={() => handleRemove(ix)} />
                          </Stack>
                        </Flex>
                      ))
                      : <Text w='100%' h='100%' display='flex' justifyContent='center' mt='3em' color='gray.400'><Translate>You can assign sets in the select above.</Translate></Text>
                    : null
                }
              </Stack>
            </Box>
          </Stack>
          <Flex justifyContent='flex-end'>
            <Button onClick={handleSubmit} isLoading={isSubmitting}>
              <Translate>{ roadmap === null ? 'Create' : 'Save' }</Translate>
            </Button>
          </Flex>
        </Box>
      </Loader>
      : <Text w='100%' h='100%' display='flex' justifyContent='center' mt='3em' color='gray.400'>
        <Translate>Select roadmap to edit or create new.</Translate>
      </Text>
  )
}

RoadmapEdit.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.oneOf([ null, 'new' ]),
  ]),
  forceRenew: PropTypes.func,
  changeId: PropTypes.func
}

export default RoadmapEdit

