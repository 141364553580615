import React, { useState, useEffect, useMemo, useContext } from 'react'
import _ from 'lodash'
import { Link as RouteLink, useHistory } from 'react-router-dom'
import { Box, FormControl, Input, InputGroup, FormLabel, InputRightElement, Divider, Heading, Text, VStack, Flex, Button, Spacer, useBreakpointValue, SimpleGrid } from '@chakra-ui/react'
import { ArrowLeftIcon, ArrowRightIcon, CheckIcon, CloseIcon } from '@chakra-ui/icons'
import Translate from '../../components/Translate'
import AddressesSection from './user-settings/AddressesSection'
import { useApiFetch } from '../../utils/api'
import ShippingInput from '../../components/inputs/ShippingInput'
import ShippingMethodSwitcher from '../../components/shippings/ShippingMethodSwitcher'
import PaymentMethodInput from '../../components/inputs/PaymentMethodInput'
import { OrderContext } from '../../contexts/OrderContext'
import { useAuth } from '../../utils/auth'
import { basketContext } from '../../utils/contexts/basket'

function Checkout() {
  const orderCtx = useContext(OrderContext)
  const { auth } = useAuth()
  const { addItem: addToBasket, state: basket } = useContext(basketContext)

  const history = useHistory()

  const [loaded, setLoaded] = useState(false)
  const [data, setData] = useState(null)

  const [address, setAddress] = useState(null)
  const [shipping, setShipping] = useState(null)
  const [shippingDetails, setShippingDetails] = useState({})
  const [payment, setPayment] = useState(null)
  const [discount, setDiscount] = useState({ code: '', valid: null })

  const [method, setMethod] = useState(null)
  const formBreakpoint = useBreakpointValue({ base: 1, xl: 2 })

  const api = useApiFetch()

  const handleLoadedAddressForm = () => setLoaded({ ...loaded, addressForm: true })

  useEffect(() => {
    if (auth.newOrderProduct && !(auth.newOrderProduct in basket)) {
      addToBasket(auth.newOrderProduct)
      auth.handleNewOrderProduct(null)
    }
  }, [])

  useEffect(() => {
    api('/store/checkout', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify({
        basket: JSON.parse(sessionStorage.getItem('lifesense-basket')),
        discount: discount.code,
        shipping
      })
    })
      .then((data) => {
        setData(data)
      })
      .catch(() => {})
      .finally(() => {
        if (Object.keys(basket).length === 0 && !auth.newOrderProduct) history.replace('/platform/cart')
      })
  }, [discount, shipping, basket])

  useEffect(() => {

    if (data && 'shippings' in data && shipping) {
      const shippings = {}
      data.shippings.forEach(({ id, ...shipping_obj }) => {
        shippings[id] = shipping_obj
      })

      const { method: newMethod } = shippings[shipping]

      if (newMethod === 'address') {
        api('/client/address/'+ address).then((response) => {
          setShippingDetails(response)
        })
          .catch(() => {})
      }
      else {
        setShippingDetails(null)
      }
      setMethod(newMethod)
    }
  }, [shipping, address])

  const discountCheck = useMemo(() => _.debounce((e) => {
    const code = e.target.value

    if (code) {
      api('/store/discount/'+ e.target.value, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json'
        },
        body: sessionStorage.getItem('lifesense-basket')
      })
        .then((response) => {
          setDiscount(response)
        })
        .catch(() => {})
    }
  }, 1000), [])

  useEffect(() => {
    return discountCheck.cancel()
  }, [])

  // useEffect(() => console.log('SHIPPING DETAILS UPDATE', shippingDetails), [shippingDetails])

  const handleMoveToSummary = () => {
    orderCtx.updateData({ address, shipping, shippingDetails, payment, data: _.omit(data, ['payment_methods']) })

    history.replace('/platform/summary')
  }

  const handleShippingClick = (id) => setShipping(id)

  const handlePaymentClick = (id) => setPayment(id)

  const handleAddressChange = (address) => setAddress(address)

  const handleShippingDetails = (data) => {
    if (typeof data === 'number') {
      api('/client/address/'+ data).then((response) => {
        setShippingDetails(response)
      })
        .catch(() => {})
    }
    else {
      setShippingDetails(data)
    }
  }

  const isMobile = useBreakpointValue({ base: true, md: false })

  return (
    <VStack>
      <Heading size='xl'>
        <Translate>Checkout</Translate>
      </Heading>
      <Text pb={8}>
        <Translate>Step 2. Please provide some details to finish order processing.</Translate>
      </Text>
      <VStack spacing={6}>
        <SimpleGrid columns={formBreakpoint} spacing={4}>
          <VStack spacing={6} w='100%'>
            <Heading size='lg' w={'100%'}>
              <Translate>Buyer&apos;s address</Translate>
            </Heading>
            <AddressesSection onLoad={handleLoadedAddressForm} onChange={handleAddressChange} inputPrefix='buyers' isSelectable={true} />
            {
              data && data.shippings && address
                ? <>
                  <Heading size='lg' w={'100%'}>
                    <Translate>Shipping</Translate>
                  </Heading>
                  {
                    data.shippings.map((data) => (
                      <ShippingInput key={'ship-' + data.id} data={data} onClick={handleShippingClick} selected={shipping === data.id} />
                    ))
                  }
                  { <ShippingMethodSwitcher method={method} address={address} handleData={handleShippingDetails} /> }
                </>
                : null
            }
            {
              data && data.payment_methods &&((data.shippings && shipping && shippingDetails) || (!data.shippings && address))
                ? <>
                  <Heading size='lg' w={'100%'}>
                    <Translate>Payment method</Translate>
                  </Heading>
                  {
                    data.payment_methods.map((data) => (
                      <PaymentMethodInput key={'pay-' + data.id} data={data} onClick={handlePaymentClick} selected={payment === data.id} />
                    ))
                  }
                </>
                : null
            }
          </VStack>
          {
            data
              ? <VStack spacing={6} alignItems={isMobile ? 'flex-start' : 'flex-end'}>
                <Box w={{ base: '100%', md: '50%', lg: '25%' }}>
                  <FormControl id="discount" isInvalid={discount.valid === false}>
                    <FormLabel fontSize='sm' textAlign={isMobile ? 'left' : 'right'}>
                      <Translate>Discount code</Translate>
                    </FormLabel>
                    <InputGroup>
                      <Input maxLength={24} size='sm' type="text" onChange={discountCheck} />
                      <InputRightElement height={8} fontSize='sm'>
                        { discount.valid !== null && discount.valid ? <CheckIcon color="green.500" boxSize='.8em' /> :(discount.valid !== null && !discount.valid ? <CloseIcon color="red.500" boxSize='.8em' /> : null) }
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>
                </Box>
                <Divider />
                <VStack spacing={4} alignItems={isMobile ? 'flex-start' : 'flex-end'}>
                  {
                    data.products.map(({ product_id, title, quantity, sum_price, price }) => (
                      <Box key={'products-'+product_id} textAlign='right'>
                        <Text>{ title } - {price.brutto.formatted} &times; { quantity }: { sum_price.brutto.formatted }</Text>
                        {
                          data.discount.products[product_id] ? <Text fontSize='sm'>
                            <Translate>Discount</Translate>
                            <span> { data.discount.products[product_id].percentage }% - { data.discount.products[product_id].name }</span>
                          </Text> : null
                        }
                      </Box>

                    ))
                  }
                </VStack>

                <Divider />
                <VStack spacing={3} alignItems={isMobile ? 'flex-start' : 'flex-end'}>
                  {
                    typeof data.discount.price === 'object'
                      ? <Text mb={1}>
                        <Translate>Discount</Translate> ({ data.discount.name }): { data.discount.price.formatted }
                      </Text>
                      : null
                  }
                  <Text>
                    <Translate>Netto</Translate>: { data.netto.formatted }
                  </Text>
                  <Text>
                    <Translate>VAT</Translate>: { data.tax.formatted }
                  </Text>
                  <Text fontSize='1.3em'>
                    <Translate>Total</Translate>: { data.total.formatted }
                  </Text>
                </VStack>
              </VStack>
              : null
          }
        </SimpleGrid>
        <Flex w={'100%'}>
          <RouteLink to='/platform/cart'>
            <Button variant='brand-ghost' leftIcon={<ArrowLeftIcon />}>
              <Translate>Back to cart</Translate>
            </Button>
          </RouteLink>
          <Spacer />
          <Button isLoading={!loaded || !data} isDisabled={!(loaded && data && ((data.shippings && shipping && shippingDetails) || (!data.shippings && address)) && payment)} rightIcon={<ArrowRightIcon />} onClick={handleMoveToSummary}>
            <Translate>Next</Translate>
          </Button>
        </Flex>
      </VStack>
    </VStack>
  )
}

export default Checkout
