import React, { useState, useEffect, useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Box, Flex, Spacer, Stack, CircularProgress, useBreakpointValue, Collapse, Fade, IconButton, Button } from '@chakra-ui/react'
import PropTypes from 'prop-types'
import { MdMenu } from 'react-icons/md'

import { useAuth } from '../../utils/auth'
import { useApiFetch } from '../../utils/api'
import Logo from '../Logo'
import DashboardHeaderUserMenu from './DashboardHeaderUserMenu'
import Translate from '../Translate'
import DashboardContext from '../../utils/contexts/dashboard'

function DashboardHeader({ onDrawerOpen }) {

  const { userMenuOnlyButton: onlyButton } = useContext(DashboardContext)
  const auth = useAuth()

  const [name, setName] = useState('')

  const history = useHistory()
  const location = useLocation()
  const api = useApiFetch()

  const logoMargin = useBreakpointValue({ base: '0 auto', md: 'inherit' })
  let isMobile = useBreakpointValue({ base: true, md: false })

  isMobile = isMobile === undefined ? true : isMobile

  useEffect(() => {

    if (!name) {
      api('/client').then((({ firstname, lastname, email }) => {
        if (onlyButton.toggle) onlyButton.setToggle(false)
        setName(firstname && lastname ? `${firstname} ${lastname}` : email)
      }))
        .catch(({ status }) => {
          if (status === 401) {
            logoutHandle()
          }
          // else if (status === 404) {
          //   if (!onlyButton.toggle) onlyButton.setToggle(true)
          //   history.replace('/platform/add-client-data')
          // }
        })
    }
  }, [name, onlyButton.toggle])

  const logoutHandle = () => {
    auth.logout(function() {
      history.replace({ pathname: '/sign-in' })
    })
  }

  const handleDrawerOpen = () => {
    onDrawerOpen()
  }

  return (
    <>
      <Collapse in={(isMobile && location.pathname === '/platform') || !isMobile} animateOpacity>
        <Box mb={10}>
          <Flex flexWrap={{ base: 'wrap', md: 'nowrap' }} align='center' justify={{ base: 'center', md: 'initial' }}>
            <Box w={{ base: '100%', md: 'initial' }} onClick={() => history.replace({ pathname: '/' })} cursor='pointer'>
              <Logo style={{ margin: logoMargin }} />
            </Box>
            <Spacer />
            <Stack w={{ base: '100%', md: 'initial' }} direction={{ base: 'column', md: 'row' }} my={{ base: 2, md: 0 }} spacing={2}>
              {
                !onlyButton.toggle ? (
                  name ?
                    <DashboardHeaderUserMenu logout={logoutHandle}>{ name }</DashboardHeaderUserMenu>
                    : <CircularProgress isIndeterminate size='1.4em' color='brand.400' my={{ base: 6, md: 0 }} />
                )
                  : <Button onClick={logoutHandle}><Translate>Logout</Translate></Button>
              }
            </Stack>
          </Flex>
        </Box>
      </Collapse>
      <Fade in={isMobile} animateOpacity unmountOnExit={true} style={{ position: 'fixed', top: 10, left: 10 }}>
        <IconButton variant='brand-ghost' icon={<MdMenu size='1.4em' />} onClick={handleDrawerOpen} />
      </Fade>
    </>
  )
}

export default DashboardHeader

DashboardHeader.propTypes = {
  onDrawerOpen: PropTypes.func
}
