import React from 'react'
import PropTypes from 'prop-types'
import { Input, InputGroup, InputLeftElement, InputRightElement, IconButton } from '@chakra-ui/react'
import { AddIcon, MinusIcon } from '@chakra-ui/icons'

function QuantityInput({ onMinus, onAdd, disabledAdd, value }) {
  return (
    <InputGroup size="sm" width={20} my={2}>
      <InputLeftElement>
        <IconButton transform='translateY(-.5em)' size='xs' variant='brand-ghost' icon={<MinusIcon />} onClick={onMinus} />
      </InputLeftElement>
      <Input readOnly value={value} textAlign='center' fontSize='1em' variant='unstyled'/>
      <InputRightElement>
        <IconButton transform='translateY(-.5em)' disabled={disabledAdd} size='xs' variant='brand-ghost' icon={<AddIcon />} onClick={onAdd} />
      </InputRightElement>
    </InputGroup>
  )
}

QuantityInput.propTypes = {
  onMinus: PropTypes.func,
  onAdd: PropTypes.func,
  disabledAdd: PropTypes.bool,
  value: PropTypes.number
}

export default QuantityInput
