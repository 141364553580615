import React from 'react'
import PropTypes from 'prop-types'
import { Box, Drawer, Stack, DrawerOverlay, DrawerContent, DrawerBody, DrawerCloseButton, useBreakpointValue, DrawerHeader, Center, Divider, VStack } from '@chakra-ui/react'
import Logo from '../Logo'

function DashboardMenu({ isOpenDrawer, onDrawerClose, source }) {

  let isMobile = useBreakpointValue({ base: true, md: false })
  isMobile = isMobile === undefined ? true : isMobile

  return (
    isMobile ?
      <Drawer
        isOpen={isOpenDrawer}
        onClose={onDrawerClose}
        placement='left'
      >
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>
              <Center>
                <VStack w={'100%'} spacing={5}>
                  <Logo src='logo-50' />
                  <Divider />
                </VStack>
              </Center>
            </DrawerHeader>
            <DrawerBody>
              { source }
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
      :
      <Box>
        <Stack direction='column' w='200px'>
          { source }
        </Stack>
      </Box>
  )
}

DashboardMenu.propTypes = {
  onDrawerClose: PropTypes.func,
  isOpenDrawer: PropTypes.bool,
  source: PropTypes.element
}

export default DashboardMenu

