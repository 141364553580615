/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

function PolishPostPickup({ isOpen, onClose, options, afterClose }) {

  const [widgetLoaded, setWidgetLoaded] = useState(false)

  useEffect(() => {
    if (!widgetLoaded) {
      // const script = document.createElement('script')
      // script.src = 'https://mapa.ecommerce.poczta-polska.pl/widget/scripts/ppwidget.js'
      // script.async = true
      // script.defer = true

      // document.body.appendChild(script)

      // window.document.dispatchEvent(new Event('DOMContentLoaded', {
      //   bubbles: true,
      //   cancelable: true
      // }))

      setWidgetLoaded(true)
    }
  }, [])

  if (isOpen) {
    PPWidgetApp.toggleMap({
      callback: (point) => {
        onClose()
        afterClose(point)
      },
      ...(options && typeof options === 'object' ? options : {})
    })
  }

  return (<></>)
}

PolishPostPickup.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  afterClose: PropTypes.func,
  options: PropTypes.object
}

export default PolishPostPickup

