import React from 'react'
import PropTypes from 'prop-types'
import { Box, Heading, Text, useColorModeValue, Button, Progress, useBreakpointValue, useDisclosure } from '@chakra-ui/react'
import { Link as RouteLink } from 'react-router-dom'

import Translate from '../../components/Translate'
import SetTrailer from './SetTrailer'
import { CheckIcon } from '@chakra-ui/icons'

function RoadmapSet({ data }) {

  const { progress, title, image, bought, price, slug } = data

  const { onOpen, isOpen, onClose } = useDisclosure()

  const gradientColor = useColorModeValue('#FFF', '#4A5568')
  const setbg = useColorModeValue('white', 'gray.600')
  const isMobile = useBreakpointValue({ base: true, md: false })
  console.log(data, price)
  return (
    <>
      <Box
        w={'calc(100% - --chakra-space-4)'}
        bg={setbg}
        border='1px solid rgba(0,0,0,.15)'
        borderRadius={{ base: '100px', md: '10px 0' }}
        p={4}
        boxShadow='md'
        position='relative'
        m={4}
        cursor='pointer'
        transition='.2s'
        height={{ base: '81px', md: 'auto' }}
        maxW={{ base: '81px', md: 'none' }}
        _hover={{ boxShadow: 'lg' }}
        {
          ...(!bought ? {
            onClick: onOpen
          } : {})
        }
      >
        <Box
          background={{
            base: `url(${image}) no-repeat center/cover`,
            md: `linear-gradient(90deg, ${gradientColor} 2%, rgba(255, 255, 255, 0) 100%), url(${image}) no-repeat center/cover`
          }}
          h='100%'
          w={{ base: '100%', md: '40%' }}
          right={0}
          top={0}
          position='absolute'
          borderRadius={{ base: '100px', md: '0 0 10px 0' }}
          zIndex={1}
          { ...(bought ? {} : { filter: 'grayscale(100%)' }) }
        ></Box>
        <Heading position='relative' zIndex={2} display={{ base: 'none', md: 'block' }} textTransform='uppercase' as='h4' size='md' fontWeight={200}>{ title }</Heading>
        {
          !isMobile ?
            bought ?
              <>
                <RouteLink to={`/platform/set/${slug}`}>
                  <Button size="xs" variant='brand-ghost' fontWeight={500} position='relative' zIndex={2} leftIcon={progress && progress === 100 ? <CheckIcon color='green' /> : null}>
                    <Translate>{ progress ? (progress === 100 ? 'Done!' : 'Continue') : 'Begin'}</Translate>
                  </Button>
                </RouteLink>
                <Progress value={progress} size="xs" colorScheme="brand" position='absolute' bottom={0} width='calc(100% - 2px)' zIndex={2} left={0} borderRadius='0 0 4px 0' />
              </>
              : <Text fontSize='md' mt={1} fontWeight='light'>
                <Translate>{ price && price.price ? price.price.brutto.formatted : 'Soon...' }</Translate>
              </Text>
            : null
        }
      </Box>
      <SetTrailer isOpen={isOpen} onClose={onClose} data={data} />
    </>
  )
}

export default RoadmapSet

RoadmapSet.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    level: PropTypes.number,
    slug: PropTypes.string,
    order: PropTypes.number,
    set: PropTypes.number,
    progress: PropTypes.number,
    title: PropTypes.string,
    image: PropTypes.string,
    bought: PropTypes.bool,
    price: PropTypes.shape({
      promotion: PropTypes.object,
      tax: PropTypes.number,
      price: PropTypes.shape({
        brutto: PropTypes.shape({
          formatted: PropTypes.string,
          bare: PropTypes.number
        })
      })
    })
  })
}
