import React from 'react'
import { Box, Icon, VStack, Heading, Text } from '@chakra-ui/react'
import { MdCancel, MdCheckCircle, MdPending } from 'react-icons/md'
import PropTypes from 'prop-types'
import Translate from '../Translate'

function OrderPaymentCheckStatus({ status, shortname }) {

  return (
    <Box>
      {
        status === 'PENDING' || status === 'CREATED'
          ? (
            <VStack spacing={6}>
              <Icon as={MdPending} w={24} h={24} color='gray.400' />
              <Heading as='h2' size='lg'>
                {
                  shortname === 'payu'
                    ? (
                      <Translate>Transaction pending...</Translate>
                    )
                    : (
                      <Translate>Waiting for transfer</Translate>
                    )
                }
              </Heading>
              <Text>
                {
                  shortname === 'payu'
                    ? (
                      <Translate>We are waiting for the payment operator to confirm your payment.</Translate>
                    )
                    : (
                      <Translate>We are waiting for the transfer to be credited to our bank account. If you haven&apos;t done it yet, please follow the instructions below.</Translate>
                    )
                }
              </Text>
            </VStack>
          ) : (
            status === 'CANCELED'
              ? (
                <VStack spacing={6}>
                  <Icon as={MdCancel} w={24} h={24} color='red.400' />
                  <Heading as='h2' size='lg'>
                    <Translate>Transaction canceled</Translate>
                  </Heading>
                  <Text><Translate>Your transaction has been canceled. No money has been withdrawn from your account.</Translate></Text>
                </VStack>
              ): (
                status === 'COMPLETED' || status === 'PAID'
                  ? (
                    <VStack spacing={6}>
                      <Icon as={MdCheckCircle} w={24} h={24} color='green.400' />
                      <Heading as='h2' size='lg'>
                        <Translate>Transaction finished!</Translate>
                      </Heading>
                      <Text><Translate>We received your payment. Thank you.</Translate></Text>
                    </VStack>
                  ) : null
              )
          )
      }
    </Box>
  )
}

export default OrderPaymentCheckStatus

OrderPaymentCheckStatus.propTypes = {
  status: PropTypes.string,
  shortname: PropTypes.string
}
