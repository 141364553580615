import React, { useEffect, useState } from 'react'
import { Box, Heading, Text, VStack, CircularProgress, Image, Button, Alert, AlertIcon } from '@chakra-ui/react'

import Translate from '../components/Translate'
import { useApiFetch } from '../utils/api'
import { uspToObject } from '../utils/functions'

export default function GrantAccess() {

  const [loaded, setLoaded] = useState(false)
  const [allowSuccessful, setAllowSuccessful] = useState(false)
  const [formSubmitting, setFormSubmitting] = useState(false)
  const [appInfo, setAppInfo] = useState({
    name: '',
    icon: '',
    received: ''
  })

  const api = useApiFetch()

  useEffect(() => {
    const info = new URLSearchParams(sessionStorage.getItem('authorize-content'))

    api(`/api-app/${info.get('client_id')}`)
      .then((response) => {
        setAppInfo({ ...response, scope: info.get('scope').split(' '), received: uspToObject(info) })
        setLoaded(true)
      })
      .catch(() => {})
  }, [])

  const handleSubmit = () => {
    setFormSubmitting(true)

    api('/authorize', {
      method: 'POST',
      body: JSON.stringify({ ...appInfo.received, scope: appInfo.received.scope.split(' ') }),
      headers: {
        'Content-type': 'application/json'
      }
    })
      .then((response) => {
        const analyzeUrl = new URL(response.redirect_to)

        setFormSubmitting(false)

        window.location = response.redirect_to
        setAllowSuccessful(true)
        sessionStorage.removeItem('authorize-content')

        if (analyzeUrl.protocol !== 'http:' && analyzeUrl.protocol !== 'https:') {
          window.close()
        }
      })
      .catch(() => {
        setFormSubmitting(false)
      })
  }

  return (
    <Box textAlign='center'>
      <VStack>
        <Heading as='h4' fontSize='2em'>
          <Translate>To continue, please agree to give access to this application.</Translate>
        </Heading>
        <Text>
          <Translate>By agreeing, you&apos;re giving access to use information on your behalf by this application.</Translate>
        </Text>
        <Box>
          {
            loaded ?
              <Box>
                <Box my={10}>
                  <Image m='0 auto' mb={6} boxSize="100px" objectFit="cover" boxShadow="0 3px 6px 0px #b9b9b9" src={appInfo.icon}></Image>
                  <Heading as='h4' fontSize='1.8em'>{ appInfo.name }</Heading>
                </Box>
                <Box my={10}>
                  <Heading as='h6' fontSize='1.1em'>
                    <Translate>Requested scopes</Translate>:
                  </Heading>
                  <Text>
                    { appInfo.scope.join(', ') }
                  </Text>
                </Box>
              </Box>
              : <CircularProgress my={20} isIndeterminate color="brand.400" />
          }
          {
            allowSuccessful ?
              <Alert status="success">
                <AlertIcon />
                <Translate>Access granted. You can now close this tab.</Translate>
              </Alert>
              : null
          }
        </Box>
        <Button colorScheme="brand" size="lg" isLoading={formSubmitting} onClick={handleSubmit} disabled={!loaded || formSubmitting || allowSuccessful}>
          Zezwól na dostęp dla tej aplikacji
        </Button>
      </VStack>
    </Box>
  )
}
