import React from 'react'
import PropTypes from 'prop-types'
import { CheckIcon } from '@chakra-ui/icons'
import { Box, Heading, HStack, Image, VStack, useColorModeValue, Stat, StatNumber, useBreakpointValue, Text } from '@chakra-ui/react'

function ShippingInput({ data: { id, name, logo, ...data }, selected, onClick }) {
  const adddressesBgColor = useColorModeValue(selected ? 'brand.400' : 'gray.200', selected ? 'gray.600' : 'gray.800')
  const adddressesBgHoverColor = useColorModeValue(selected ? 'brand.400' : 'gray.100', selected ? 'gray.600' : 'gray.900')
  const fontColor = useColorModeValue(selected ? 'white' : 'gray.800', selected ? 'white' : 'white')
  const isMobile = useBreakpointValue({ base: true, md: false })

  const price = 'price' in data ? data.price.formatted : null

  const handleClick = () => onClick && price ? onClick(id) : null

  return (
    price ?
      <Box shadow='sm' position='relative' p={4} borderRadius='15px' transition='.2s' cursor='pointer' w='100%' bg={adddressesBgColor} _hover={{ bg: adddressesBgHoverColor }} color={fontColor} onClick={handleClick}>
        <HStack>
          { selected ? <CheckIcon color={fontColor} w={5} h={5} /> : null }
          <Image src={logo} h={10} maxW={70} objectFit='contain'/>
          <VStack pl={2}>
            <Heading as='h5' size='md' w={'100%'}>{ name }</Heading>
            {
              isMobile ?
                <Text w={'100%'}>{ price }</Text>
                : null
            }
          </VStack>
          {
            !isMobile ?
              <Stat textAlign='right'>
                <StatNumber>{ price }</StatNumber>
              </Stat>
              : null
          }
        </HStack>
      </Box>
      : null
  )
}

ShippingInput.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    logo: PropTypes.string,
    price: PropTypes.shape({
      formatted: PropTypes.string
    })
  }),
  onClick: PropTypes.func,
  selected: PropTypes.bool
}

export default ShippingInput
