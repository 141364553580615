import React from 'react'
import { Route, useRouteMatch } from 'react-router-dom'

import { OrderContextProvider } from './contexts/OrderContext'

import Dashboard from './pages/dashboard/Dashboard'
import UserSettings from './pages/dashboard/UserSettings'
import Sets from './pages/dashboard/Sets'
import Set from './pages/dashboard/Set'
import Store from './pages/dashboard/Store'
import Roadmaps from './pages/dashboard/Roadmaps'
import Cart from './pages/dashboard/Cart'
import Checkout from './pages/dashboard/Checkout'
import Summary from './pages/dashboard/Summary'
import OrderPayment from './pages/dashboard/orders/OrderPayment'
import OrderPaymentCheck from './pages/dashboard/orders/OrderPaymentCheck'

function DashboardRouter() {

  const { path } = useRouteMatch()

  return (
    <>
      <Route exact path={path}>
        <Dashboard />
      </Route>

      <Route path={`${path}/settings`}>
        <UserSettings />
      </Route>

      <Route path={`${path}/roadmap`}>
        <Roadmaps />
      </Route>

      <Route path={`${path}/sets`}>
        <Sets />
      </Route>

      <Route path={`${path}/set/:slug`}>
        <Set />
      </Route>

      <Route path={`${path}/store`}>
        <Store />
      </Route>

      <Route path={`${path}/orders`}>
        {/* <Orders /> */}
      </Route>

      <Route path={`${path}/orders/:uuid`} exact={true}>
        {/* <Orders /> */}
      </Route>

      <Route path={`${path}/orders/:uuid/payment`} exact={true}>
        <OrderPayment />
      </Route>

      <Route path={`${path}/orders/:uuid/payment/check`} exact={true}>
        <OrderPaymentCheck />
      </Route>

      <OrderContextProvider>
        <Route path={`${path}/cart`}>
          <Cart />
        </Route>

        <Route path={`${path}/checkout`}>
          <Checkout />
        </Route>

        <Route path={`${path}/summary`}>
          <Summary />
        </Route>
      </OrderContextProvider>
    </>
  )
}

export default DashboardRouter
