import React from 'react'
import { Route, useRouteMatch } from 'react-router-dom'

import Dashboard from './pages/admin/Dashboard'
import Orders from './pages/admin/Orders'
import Set from './pages/admin/Set'
import Sets from './pages/admin/Sets'

function AdminRouter() {

  const { path } = useRouteMatch()

  return (
    <>
      <Route exact path={path} >
        <Dashboard />
      </Route>

      <Route path={`${path}/settings`} >
        Settings
      </Route>

      <Route path={`${path}/sets`} >
        <Sets />
      </Route>

      <Route path={`${path}/orders`} >
        <Orders />
      </Route>

      <Route path={[
        `${path}/set-editor/create`,
        `${path}/set-editor/:id`
      ]} >
        <Set />
      </Route>
    </>
  )
}

export default AdminRouter
