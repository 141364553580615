import React from 'react'
import { Link } from 'react-router-dom'
import { Box } from '@chakra-ui/react'

function Admin() {

  return (
    <Box>
      <p>Admin Page</p>
      <Link to={'/'}>Home</Link>
      <Link to={'/user'}>User</Link>
    </Box>
  )
}

export default Admin
