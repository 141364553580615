import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { HStack, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure, Textarea, FormControl, FormHelperText } from '@chakra-ui/react'
import Translate from '../Translate'

function ShortDescriptionDialog({ initialValue, disclosure, change }) {
  const [value, setValue] = useState(initialValue || [])

  const ownDisclosure = useDisclosure()

  const { isOpen, onClose, handleChange } = disclosure || ownDisclosure

  const cancelRef = useRef()

  const handleClick = () => {
    if (handleChange) handleChange(value)
    if (change) change(value)

    onClose()
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="lg" fontWeight="bold">
          <Translate>Change short description</Translate>
        </ModalHeader>

        <ModalBody>

          <FormControl id="short_description">
            <Textarea
              maxLength={300}
              size='sm'
              resize='vertical'
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
            <FormHelperText>{ value.length }/300</FormHelperText>
          </FormControl>

        </ModalBody>

        <ModalFooter>
          <HStack spacing={2}>
            <Button ref={cancelRef} onClick={onClose} ml={3} variant='brand-ghost'>
              <Translate>Cancel</Translate>
            </Button>
            <Button onClick={handleClick}>
              <Translate>Change</Translate>
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

ShortDescriptionDialog.propTypes = {
  initialValue: PropTypes.string,
  disclosure: PropTypes.shape({
    isOpen: PropTypes.bool,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    handleChange: PropTypes.func
  }),
  open: PropTypes.func,
  change: PropTypes.func
}

export default ShortDescriptionDialog
