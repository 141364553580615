import React from 'react'
import PropTypes from 'prop-types'
import { Flex, Box, CircularProgress } from '@chakra-ui/react'

function Loader({ loaded, children, ...props }) {
  return (
    <>
      <Flex display={loaded ? 'none' : 'flex'} align='center' justify='center' h='100%' py={10}>
        <CircularProgress {...props} my={{ base: 6, md: 0 }} />
      </Flex>
      <Box display={loaded ? 'block' : 'none'} >
        { children }
      </Box>
    </>
  )
}

Loader.propTypes = {
  loaded: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.string
  ])
}

Loader.defaultProps = {
  loaded: true,
  isIndeterminate: true,
  color: 'brand.400'
}

export default Loader

