import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import SetPage from '../../pages/dashboard/SetPage'
import { TabPanel, Tabs, TabList, Tab, TabPanels, IconButton } from '@chakra-ui/react'
import { AddIcon } from '@chakra-ui/icons'
import SetContentContext from '../../contexts/SetContentContext'

function SetEditing({ addPage, removePage, handleUpdate }) {
  const { content: { pages } } = useContext(SetContentContext)

  return (
    <Tabs size="md" variant="enclosed">
      <TabList>
        {
          pages.map((page) => (
            <Tab key={'pt-'+ page.no}>
              { page.no }
            </Tab>
          ))
        }
        <IconButton icon={<AddIcon />} onClick={addPage} variant='brand-ghost' />
      </TabList>
      <TabPanels>
        {
          pages.map((page, ix) => (
            <TabPanel key={'sp-'+ page.no}>
              <SetPage page={ix} isEditable={true} remove={removePage} updateContent={handleUpdate} canDeletePage={pages.length > 1}/>
            </TabPanel>
          ))
        }
      </TabPanels>
    </Tabs>
  )
}

SetEditing.propTypes = {
  content: PropTypes.oneOfType([
    PropTypes.shape({
      pages: PropTypes.arrayOf(PropTypes.object)
    }).isRequired,
    PropTypes.oneOf([null])
  ]),
  addPage: PropTypes.func,
  movePageLeft: PropTypes.func,
  movePageRight: PropTypes.func,
  removePage: PropTypes.func,
  handleUpdate: PropTypes.func
}

export default SetEditing
