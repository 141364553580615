import React, { useState } from 'react'
import PropTypes from 'prop-types'

export const OrderContext = React.createContext()

export function OrderContextProvider({ children }) {

  const [data, setData] = useState({})

  const updateData = (content) => setData(content)

  return (
    <OrderContext.Provider value={{ data, updateData }}>
      { children }
    </OrderContext.Provider>
  )
}

OrderContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ])
}
