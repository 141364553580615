import React, { Suspense } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Box, Link, Text, Stack, InputLeftElement, Icon, Checkbox, FormControl, FormHelperText, Center, useBreakpointValue } from '@chakra-ui/react'
import { MdEmail, MdGroup, MdLock, MdPerson } from 'react-icons/md'
import i18next from 'i18next'
import _ from 'lodash'
import ReactMarkdown from 'react-markdown'

import EmailInput from '../inputs/EmailInput'
import PasswordInput from '../inputs/PasswordInput'
import TextInput from '../inputs/TextInput'

function RegisterAuthFormTT({ errors, passInput, agreements }) {

  const namesBreakpoint = useBreakpointValue({ base: 'column', md: 'row' })

  if (errors) var {
    firstname: errFirstname,
    lastname: errLastname,
    email: errEmail,
    password: errPassword,
    repeatPassword: errRepeatPassword,
    agreements: errAgreement,
    recaptcha: errRecaptcha
  } = errors

  const { t } = useTranslation()

  errAgreement = errAgreement || { rules: '', gdpr: '' }

  return (
    <Stack spacing='16px'>
      <Stack direction={namesBreakpoint}>
        <TextInput
          name='firstname'
          label={ t('Firstname') }
          error={ t(errFirstname) }
          onInput={(e) => passInput('firstname', e)}
          innerLabel
          inputLeft={
            <InputLeftElement pointerEvents="none">
              <Icon as={MdPerson} color="gray.300" />
            </InputLeftElement>
          }
          autocomplete="new-name"
        />
        <TextInput
          name='lastname'
          label={ t('Lastname') }
          error={ t(errLastname) }
          onInput={(e) => passInput('lastname', e)}
          innerLabel
          inputLeft={
            <InputLeftElement pointerEvents="none">
              <Icon as={MdGroup} color="gray.300" />
            </InputLeftElement>
          }
          autocomplete="new-name"
        />
      </Stack>
      <EmailInput
        name='email'
        label={ t('E-mail') }
        error={ t(errEmail) }
        onInput={(e) => passInput('email', e)}
        innerLabel
        inputLeft={
          <InputLeftElement pointerEvents="none">
            <Icon as={MdEmail} color="gray.300" />
          </InputLeftElement>
        }
        autocomplete="new-email"
      />
      <PasswordInput
        name='password'
        label={ t('Password') }
        error={ t(errPassword) }
        onInput={(e) => passInput('password', e)}
        innerLabel
        inputLeft={
          <InputLeftElement pointerEvents="none">
            <Icon as={MdLock} color="gray.300" />
          </InputLeftElement>
        }
        autocomplete="new-password"
      />
      <PasswordInput
        name='repeatPassword'
        label={ t('Repeat password') }
        error={ t(errRepeatPassword) }
        onInput={(e) => passInput('repeatPassword', e)}
        innerLabel
        inputLeft={
          <InputLeftElement pointerEvents="none">
            <Icon as={MdLock} color="gray.300" />
          </InputLeftElement>
        }
        autocomplete="new-password"
      />
      <Stack spacing={1} textAlign='left'>
        {
          agreements
            ? _.map(agreements, ({ id, name, content, required }) => (
              <FormControl key={'agreement-'+ id} isRequired={!!required}>
                <Checkbox isInvalid={errAgreement[name] || false} spacing={4} name={'agreement-'+ name} onChange={(e) => passInput('agreement-'+ name, e)}>
                  <ReactMarkdown>{ content[i18next.language || 'en-GB'] }</ReactMarkdown>
                </Checkbox>
                { errAgreement[name] ? <FormHelperText color='red.400'><ReactMarkdown>{ errAgreement[name] }</ReactMarkdown></FormHelperText> : null }
              </FormControl>
            ))
            : null
        }

      </Stack>
      {
        errRecaptcha
          ? <Center>
            <Text color='red.400'>{ errRecaptcha }</Text>
          </Center>
          : null
      }
      <Box>
        <Text lineHeight={1.4} fontSize={14} color='gray.400'>
          { t('This site is protected by reCAPTCHA and the Google') }
        </Text>
        <Text lineHeight={1.4} fontSize={14} color='gray.400'>
          <Link href="https://policies.google.com/privacy" rel="noreferrer" target="_blank">{ t('Privacy policy') }</Link> { t('and') } <Link href="https://policies.google.com/terms" rel="noreferrer" target="_blank">{ t('Terms of service') }</Link> { t('apply.') }
        </Text>
      </Box>
    </Stack>
  )
}

RegisterAuthFormTT.propTypes = {
  errors: PropTypes.shape({
    firstname: PropTypes.string,
    lastname: PropTypes.lastname,
    email: PropTypes.string,
    password: PropTypes.string,
    repeatPassword: PropTypes.string,
    agreements: PropTypes.shape({
      rules: PropTypes.string,
      gdpr: PropTypes.string
    }),
    recaptcha: PropTypes.string
  }),
  passInput: PropTypes.func,
  agreements: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      content: PropTypes.object,
      required: PropTypes.oneOfType([ PropTypes.number, PropTypes.bool ]),
    })
  )
}

export default function RegisterAuthForm(props) {
  return (
    <Suspense fallback='loading'>
      <RegisterAuthFormTT { ...props }/>
    </Suspense>
  )
}
