import React, { Suspense } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

function TranslateWaitingForSuspense({ children, options = {} }) {
  const { t } = useTranslation()

  return (
    <span>{ t(children, options) }</span>
  )
}

export default function Translate(props) {

  return (
    <Suspense fallback='Loading...'>
      <TranslateWaitingForSuspense { ...props } />
    </Suspense>
  )
}

TranslateWaitingForSuspense.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  options: PropTypes.object
}
