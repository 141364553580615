import axios from 'axios'
import videojs from 'video.js'

const renderComponent = (opt) => {

  opt = opt || {
    default: ''
  }

  const container = document.createElement('div')

  const input = document.createElement('input')

  input.type = 'hidden'
  input.name = 'video'
  input.value = opt.default || null

  const file = document.createElement('input')

  file.type = 'file'
  file.accept = 'video/mp4,zip,application/zip,application/x-zip,application/x-zip-compressed'

  file.addEventListener('change', (e) => {
    uploadFile(e.target.files[0])
  })

  const state = {
    view: opt.default ? 'videoPlayer' : 'initial'
  }

  const renderInit = (...args) => {
    return viewWrapper(render.views[state.view](args))
  }

  const changeView = (view, ...args) => {
    state.view = view

    renderInit(...args)
  }

  const viewWrapper = (children) => {
    container.innerHTML = ''

    container.appendChild(input)
    container.appendChild(children)

    return container
  }

  const uploadFile = (file) => {
    const formData = new FormData()
    formData.append('video', file)

    axios.post((process.env.REACT_APP_API || 'http://localhost:8000') + '/admin/sets/video', formData, {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      },
      onUploadProgress: ({ loaded, total }) => {
        changeView('progress', Math.floor( (loaded / total) * 100 ), 'Wysyłanie pliku')
      }
    }).then(async ({ status, data })=> {

      if (status === 202) {
        const { convertionCheckUrl } = data

        let interval = null

        const checkConversion = async () => {
          const response = await fetch(convertionCheckUrl, {
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
          })

          if (response.status === 200) {
            const { completed, percent, url } = await response.json()

            if (!completed) {
              changeView('progress', Math.floor( percent ), 'Konwertowanie')
            }
            else {
              clearInterval(interval)
              input.value = url
              changeView('videoPlayer', url)
            }

          } else {
            clearInterval(interval)
          }
        }

        interval = setInterval(checkConversion, 4000)
      }
      else {
        const { url } = data

        changeView('videoPlayer', url)
      }
    }).catch(() => {
      changeView('initial')
    })
  }

  const render = {
    views: {
      initial: () => {
        const p = document.createElement('p')

        p.innerHTML = 'Proszę wybrać plik wideo z formatem .mp4'
        const div = document.createElement('div')
        div.style.margin = '10px 0'

        div.append(p)
        div.append(file)

        return div
      },
      progress: ([ percent, message ]) => {
        const wrapper = document.createElement('div')
        wrapper.style.height = '24px'
        wrapper.style.width = '100%'
        wrapper.style.position = 'relative'
        wrapper.style.background = '#DEDEDE'
        wrapper.style.margin = '10px 0'

        const progress = document.createElement('div')
        progress.style.position = 'absolute'
        progress.style.top = '0'
        progress.style.height = '100%'
        progress.style.background = '#009CA3'
        progress.style.width = percent + '%'
        progress.style.zIndex = '10'

        const p = document.createElement('p')
        p.style.color = '#FFF'
        p.innerHTML = (message ? message + ' - ' : '') + `${percent}%...`
        p.style.position = 'absolute'
        p.style.top = '0'
        p.style.zIndex = '20'
        p.style.margin = '0'
        p.style.paddingLeft = '4px'

        wrapper.appendChild(progress)
        wrapper.appendChild(p)

        return wrapper
      },
      videoPlayer: ([ url ]) => {

        const playerWrapper = document.createElement('div')
        playerWrapper.setAttribute('data-vjs-player', 'true')
        playerWrapper.style.margin = '10px 0'

        const player = document.createElement('video')
        player.classList.add('video-js', 'vjs-theme-forest')

        const containerObserver = new MutationObserver(function() {
          if (document.contains(player)) {
            const instance = videojs(player, {
              width: 500,
              autoplay: false,
              controls: true,
              responsive: true,
              fluid: true,
              sources: {
                src: url,
                type: 'application/x-mpegURL'
              }
            })

            instance.reloadSourceOnError({
              getSource: function(reload) {
                reload({
                  src: url,
                  type: 'application/x-mpegURL'
                })
              },

              errorInterval: 5
            })

            instance.on('error', (e) => {
              console.error(e)
            })

            containerObserver.disconnect()
          }
        })

        containerObserver.observe(document, { attributes: true, childList: true, subtree: true })

        playerWrapper.appendChild(player)

        return playerWrapper
      }
    }
  }

  return renderInit(opt.default || null)
}

class Video {

  constructor({ data }){
    this.url = 'url' in data ? data.url : ''
  }

  static get toolbox() {
    return {
      title: 'Video',
      icon: '<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M18 4l2 4h-3l-2-4h-2l2 4h-3l-2-4H8l2 4H7L5 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V4h-4z"/></svg>'
    }
  }

  render() {
    return renderComponent({ default: this.url })
  }

  save(blockContent){

    return {
      url: blockContent.querySelector('input[type=hidden]').value
    }
  }
}

export default Video
