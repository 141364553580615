import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Heading, Image, Text, Skeleton, SkeletonText, VStack, useColorModeValue, Stack, Button, useDisclosure, Tag, TagLabel, TagLeftIcon } from '@chakra-ui/react'
import { MdSearch } from 'react-icons/md'
import { IoMdPricetag } from 'react-icons/io'

import Translate from '../Translate'
import SetTrailer from './SetTrailer'
import Roadmap from '../../components/sets/Roadmap'

function RoadmapProduct({ id: roadmap_id, levels, data }) {

  const [imgLoaded, setImgLoaded] = useState(false)

  const setBg = useColorModeValue('gray.50', 'gray.600')

  const { onOpen, isOpen, onClose } = useDisclosure()

  const { title = '', image, short_description, price, promotion = null } = data

  const handleImageLoad = () => {
    if (!imgLoaded) setImgLoaded(true)
  }

  return (
    <>
      <Box background={setBg} borderRadius={2} boxShadow='base' _hover={{ boxShadow: 'lg' }} transition={'.2s'} cursor='pointer' position='relative'>
        <Skeleton isLoaded={imgLoaded}>
          <Image h={{ base: 124, lg: 168 }} src={image} onLoad={handleImageLoad} bg='gray.200' width='100%' objectFit='cover' />
        </Skeleton>
        <Box pos='absolute' h={{ base: 124, lg: 168 }} w='100%' bg='rgba(0,0,0,0)' top={0}></Box>
        <VStack spacing={2} px={4} pt={4} pb={6} align='flex-start' w='100%'>
          {
            data ?
              <VStack w='100%' spacing={3} alignItems='flex-start'>
                <Heading as='h4' size='md' textTransform='uppercase'>
                  { title }
                  {/* { (added_date + (3600 * 24 * 30)) > (Date.now() / 1000) ? <Badge ml={2} variant="subtle" colorScheme="green"><Translate>NEW</Translate></Badge> : null } */}
                  {/* { promotion ? <Badge ml={2} variant="solid" colorScheme="red"><Translate>PROMOTION!</Translate> -{promotion.percentage}%</Badge> : null } */}
                </Heading>
                <Text fontSize='.9em'>{ short_description.split(' ').splice(0,128).join(' ') + '...' }</Text>
                <Stack spacing={2} direction={{ base: 'column', xl: 'row' }} w={'100%'} align={{ base: 'center', xl: 'flex-start' }}>
                  <Button onClick={onOpen} leftIcon={<MdSearch />} variant='brand-solid' size='sm' w={{ base: '100%', xl: 'calc(100% - 130px)'}}>
                    <Translate>See details</Translate>
                  </Button>
                  {
                    price ? <Tag
                      size='lg'
                      borderRadius="full"
                      colorScheme={promotion ? 'red' : ''}
                    >
                      <TagLeftIcon boxSize="20px" as={IoMdPricetag} />
                      <TagLabel variant="brand-solid">{ price.brutto.formatted }</TagLabel>
                    </Tag>
                      : null
                  }
                </Stack>
                <Box w='100%'>
                  <Roadmap id={roadmap_id} data={levels} />
                </Box>
              </VStack>
              :
              <>
                <Skeleton noOfLines={1} w={'100%'}></Skeleton>
                <SkeletonText w={'100%'} noOfLines={5}></SkeletonText>
              </>
          }

        </VStack>
      </Box>
      <SetTrailer isOpen={isOpen} onClose={onClose} data={{ id: roadmap_id, ...data }} />
    </>
  )
}

RoadmapProduct.propTypes = {
  id: PropTypes.number,
  levels: PropTypes.object,
  title: PropTypes.string,
  data: PropTypes.shape({
    title: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    progress: PropTypes.integer,
    image: PropTypes.string,
    short_description: PropTypes.string,
    price: PropTypes.shape({
      brutto: PropTypes.shape({
        formatted: PropTypes.string,
        bare: PropTypes.number
      }),
      tax: PropTypes.number
    }),
    promotion: PropTypes.shape({
      percentage: PropTypes.number,
      oldprice: PropTypes.string,
      to: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([ null ])])
    })
  })
}

export default RoadmapProduct

