import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, Checkbox, useDisclosure, Stack, Text } from '@chakra-ui/react'

import Translate from '../Translate'
import InPostGeoWidget from './InPostGeoWidget'
import PolishPostPickup from './PolishPostPickup'
import AddressesSection from '../../pages/dashboard/user-settings/AddressesSection'

function ShippingMethodSwitcher({ method, address, handleData }) {

  const [packageLocker, setPackageLocker] = useState(null)
  const [pickupPoint, setPickupPoint] = useState(null)

  const [showDeliveryAddress, setShowDeliveryAddress] = useState(false)
  const { onOpen: onInpostOpen, isOpen: isInpostOpen, onClose: onInpostClose } = useDisclosure()
  const { onOpen: onPPOpen, isOpen: isPPOpen, onClose: onPPClose } = useDisclosure()

  const handleOnChange = (e) => setShowDeliveryAddress(!e.target.checked)

  const sendInpostDataHigher = (data) => {
    setPackageLocker(data)
    setPickupPoint(null)
    handleData ? handleData(data) : null
  }

  const sendPPDataHigher = (data) => {
    setPackageLocker(null)
    setPickupPoint(data)
    handleData ? handleData(data) : null
  }

  const sentAddrDataHigher = (id) => {
    setPackageLocker(null)
    setPickupPoint(null)
    handleData ? handleData(id) : null
  }

  useEffect(() => {
    !showDeliveryAddress && handleData ? handleData(address) : null
  }, [showDeliveryAddress])

  switch(method) {

  case 'inpost_parcel_lockers' :

    return (
      <>
        <Stack w='100%' direction={{ base: 'column', md: 'row' }} justify={{ base: 'center', md: 'flex-start' }} align='center' >
          <Button w={{ base: '100%', md: 'initial' }} onClick={onInpostOpen} >Wybierz paczkomat</Button>
          <Text>
            <Translate options={{ packageLocker: packageLocker ? `${packageLocker.name} | ${packageLocker.address.line1}, ${packageLocker.address.line2}` : '' }}>{'Selected package locker - {{packageLocker}}'}</Translate>
          </Text>
        </Stack>
        <InPostGeoWidget isOpen={isInpostOpen} onClose={onInpostClose} afterClose={sendInpostDataHigher} />
      </>
    )

  case 'address' :

    return (
      <>
        <Checkbox defaultIsChecked={!showDeliveryAddress} onChange={handleOnChange} w={'100%'}>
          <Translate>Shipping address is the same as above</Translate>
        </Checkbox>
        {
          showDeliveryAddress ?
            <AddressesSection onChange={sentAddrDataHigher} inputPrefix='shipping' isSelectable={true} />
            : null
        }
      </>
    )

  case 'pp_pickup' :

    return (
      <>
        <Stack w='100%' direction={{ base: 'column', md: 'row' }} justify={{ base: 'center', md: 'flex-start' }} align='center' >
          <Button w={{ base: '100%', md: 'initial' }} onClick={onPPOpen} >Wybierz punkt</Button>
          <Text>
            <Translate options={{ pickupPoint: pickupPoint ? `${pickupPoint.name} | ${pickupPoint.street}, ${pickupPoint.zipCode} ${pickupPoint.city}` : '' }}>{'Selected pickup point - {{pickupPoint}}'}</Translate>
          </Text>
        </Stack>
        <PolishPostPickup isOpen={isPPOpen} onClose={onPPClose} afterClose={sendPPDataHigher} />
      </>
    )

  default :
    return (<></>)
  }
}

ShippingMethodSwitcher.propTypes = {
  method: PropTypes.string,
  address: PropTypes.number,
  handleData: PropTypes.func
}

export default ShippingMethodSwitcher

