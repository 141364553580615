import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { Text, Box, Stack, Tabs, TabList, Tab, TabPanels, TabPanel, Table, Thead, Tbody, Tr, Th, Td, Icon, IconButton, HStack, Tooltip, Flex, Spacer, Select, Button } from '@chakra-ui/react'
import { CheckIcon, CloseIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons'
import { Link } from 'react-router-dom'
import { MdArchive } from 'react-icons/md'
import { useTranslation } from 'react-i18next'
import { CheckRole } from '../../utils/auth'
import Translate from '../../components/Translate'
import Loader from '../../components/Loader'
import { useApiFetch } from '../../utils/api'
import { dmyhm_date } from '../../utils/date'
import ArchiveDialog from '../../components/sets/ArchiveDialog'
import RoadmapEdit from '../../components/sets/RoadmapEdit'

function Sets() {

  const api = useApiFetch()

  const [sets, setSets] = useState(null)
  const [setsRoadmaps, setSetsRoadmaps] = useState([])

  const [roadmaps, setRoadmaps] = useState(null)
  const [editRoadmap, setEditRoadmap] = useState(null)
  const [confirmMode, setConfirmMode] = useState(false)

  const [dialogSet, setDialogSet] = useState({ data: null, type: 'archive' })
  const [dialogRoadmaps, setDialogRoadmaps] = useState({ data: null, type: 'archive' })

  const [roadmapFilter, setRoadmapFilter] = useState('all')

  const [forceRenewSets, setForceRenewSets] = useState(true)
  const [forceRenewRoadmaps, setForceRenewRoadmaps] = useState(true)

  const { t } = useTranslation()

  useEffect(() => {
    if (forceRenewSets) {
      api('/admin/sets')
        .then((response) => {
          setSets(response)
          setSetsRoadmaps(_(response.map(({ roadmaps }) => roadmaps)).flatten().uniq().value())
          setForceRenewSets(false)
        })
        .catch(() => {})
    }

    if (forceRenewRoadmaps) {
      api('/admin/roadmaps')
        .then((response) => {
          setRoadmaps(response)
          setForceRenewRoadmaps(false)
        })
        .catch(() => {})
    }
  }, [forceRenewSets, forceRenewRoadmaps])

  const viewed_sets = !sets ? [] : sets.filter((set) => {
    switch(roadmapFilter) {

    case 'all' :
      return set.archived === 0

    case 'unassigned':
      return set.archived === 0 && set.roadmaps.length === 0

    case 'archived':
      return set.archived === 1

    default :
      return set.archived === 0 && set.roadmaps.includes(roadmapFilter)

    }
  })

  const handleSetArchiveClick = (data) => {
    setDialogSet({ data, type: 'archive' })
  }

  const handleSetDeleteClick = (data) => {
    setDialogSet({ data, type: 'delete' })
  }

  // const handleRoadmapArchiveClick = (data) => {
  //   setDialogRoadmaps({ data, type: 'archive' })
  // }

  const handleRoadmapDeleteClick = (data) => {
    setDialogRoadmaps({ data, type: 'delete' })
  }

  const handleForceRenewSets = () => setForceRenewSets(true)
  const handleForceRenewRoadmaps = () => {
    setEditRoadmap(null)
    setForceRenewRoadmaps(true)
  }

  const handleEditRoadmap = (target) => {

    if (editRoadmap !== null && confirmMode !== target) {
      setConfirmMode(target)
    }
    else if (editRoadmap === null || (editRoadmap !== null && confirmMode === target)) {
      setEditRoadmap(target)
      setConfirmMode(false)
    }
  }

  const handleChangeId = (id) => {
    setEditRoadmap(id)
  }

  return (
    <CheckRole role='admin' redirect='/admin'>
      <Loader loaded={sets !== null && roadmaps !== null}>
        <ArchiveDialog object={'set'} data={dialogSet.data} type={dialogSet.type} forceRenew={handleForceRenewSets} />
        <ArchiveDialog object={'roadmap'} data={dialogRoadmaps.data} type={dialogRoadmaps.type} forceRenew={handleForceRenewRoadmaps} />
        <Tabs>
          <TabList>
            <Tab>Ścieżki</Tab>
            <Tab>Komplety</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Flex>
                <Spacer />
                {
                  confirmMode
                    ? <Stack direction='row' spacing={2}>
                      <Text colorScheme='red' display='flex' alignItems='center'>
                        <Translate>Your content has changed. Do you want to proceed?</Translate>
                      </Text>
                      <IconButton bg='green.500' icon={<CheckIcon />} onClick={() => { handleEditRoadmap(confirmMode) }} />
                      <IconButton bg='red.500' icon={<CloseIcon />} onClick={() => { setConfirmMode(false) }} />
                    </Stack>
                    : <Button onClick={() => { handleEditRoadmap('new') }}>
                      <Translate>Create roadmap</Translate>
                    </Button>
                }
              </Flex>
              <Stack direction={{ base: 'column', xl: 'row' }}>
                <Box w={{ base: '100%', xl: '35%' }}>
                  <Table variant="simple" mt={4}>
                    <Thead>
                      <Tr>
                        <Th>
                          <Translate>Name</Translate>
                        </Th>
                        <Th>
                          <Translate>Actions</Translate>
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {
                        roadmaps && roadmaps.length ? roadmaps.map(({ id, title, isDeletable = true }) => (
                          <Tr key={'roadmap-' + id}>
                            <Td>{ title }</Td>
                            <Td>
                              <HStack spacing={2}>
                                <Tooltip label={<Translate>Edit</Translate>}>
                                  <IconButton isDisabled={confirmMode} icon={<EditIcon />} onClick={() => handleEditRoadmap(id)} />
                                </Tooltip>
                                {
                                  isDeletable ?
                                    <Tooltip label={<Translate>Delete</Translate>}>
                                      <IconButton icon={<DeleteIcon />} onClick={() => { handleRoadmapDeleteClick({ id, title }) }} />
                                    </Tooltip>
                                    : null
                                }
                                {/* <Tooltip label={<Translate>Archive</Translate>}>
                                  <IconButton icon={<MdArchive />} onClick={() => { handleRoadmapArchiveClick({ id, title }) }} />
                                </Tooltip> */}
                              </HStack>
                            </Td>
                          </Tr>
                        )) : <Tr><Td colSpan={5} textAlign='center'><Translate>No roadmaps</Translate></Td></Tr>
                      }
                    </Tbody>
                  </Table>
                </Box>
                <Box w={{ base: '100%', xl: '65%' }}>
                  <RoadmapEdit id={editRoadmap} forceRenew={handleForceRenewRoadmaps} changeId={handleChangeId} />
                </Box>
              </Stack>
            </TabPanel>
            <TabPanel>
              <Flex>
                <Select mr={4} defaultValue='all' onChange={(e) => setRoadmapFilter(e.target.value)}>
                  <option value="all">{ t('All roadmaps') }</option>
                  { setsRoadmaps.map((roadmap) => <option key={'rm-'+ roadmap} value={roadmap}>{ roadmap }</option>) }
                  <option value="unassigned">{ t('Without roadmap') }</option>
                  <option value="archived">{ t('Archived') }</option>
                </Select>
                <Spacer />
                <Link to='/admin/set-editor/create'>
                  <Button>
                    <Translate>Create set</Translate>
                  </Button>
                </Link>
              </Flex>
              <Table variant="simple" mt={4}>
                <Thead>
                  <Tr>
                    <Th>
                      <Translate>Name</Translate>
                    </Th>
                    <Th>
                      <Translate>Created at</Translate>
                    </Th>
                    <Th>
                      <Translate>Public</Translate>
                    </Th>
                    <Th>
                      <Translate>Active</Translate>
                    </Th>
                    <Th>
                      <Translate>Actions</Translate>
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {
                    viewed_sets.length ? viewed_sets.map(({ id, title, created_at, public: is_public, active, isDeletable, archived }) => (
                      <Tr key={'set-' + id}>
                        <Td>{ title }</Td>
                        <Td>{ dmyhm_date(created_at) }</Td>
                        <Td>
                          {
                            is_public
                              ? <Icon as={CheckIcon} color='green' />
                              :<Icon as={CloseIcon} color='red' />
                          }
                        </Td>
                        <Td>
                          {
                            active
                              ? <Icon as={CheckIcon} color='green' />
                              :<Icon as={CloseIcon} color='red' />
                          }
                        </Td>
                        <Td>
                          <HStack spacing={2}>
                            <Link to={`/admin/set-editor/${id}`}>
                              <Tooltip label={<Translate>Edit</Translate>}>
                                <IconButton icon={<EditIcon />} />
                              </Tooltip>
                            </Link>
                            {
                              isDeletable ?
                                <Tooltip label={<Translate>Delete</Translate>}>
                                  <IconButton icon={<DeleteIcon />} onClick={() => { handleSetDeleteClick({ id, title }) }} />
                                </Tooltip>
                                : null
                            }
                            {
                              !archived ?
                                <Tooltip label={<Translate>Archive</Translate>}>
                                  <IconButton icon={<MdArchive />} onClick={() => { handleSetArchiveClick({ id, title }) }} />
                                </Tooltip>
                                :null
                            }
                          </HStack>
                        </Td>
                      </Tr>
                    )) : <Tr><Td colSpan={5} textAlign='center'><Translate>No sets</Translate></Td></Tr>
                  }
                </Tbody>
              </Table>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Loader>
    </CheckRole>
  )
}

const SetsFallback = (props) => (
  <React.Suspense fallback='Loading...'>
    <Sets {...props} />
  </React.Suspense>
)

export default SetsFallback
