import React, { useState, useEffect } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { MdDashboard, MdTimeline, MdSecurity } from 'react-icons/md'

import DashboardMenuItem from '../dashboard/DashboardMenuItem'
import DashboardMenuDivider from '../dashboard/DashboardMenuDivider'
import Translate from '../Translate'

import { useAuth } from '../../utils/auth'

function PlatformMenu() {

  const [isAdmin, setIsAdmin] = useState(false)

  const { user } = useAuth()
  const { url } = useRouteMatch()

  useEffect(() => {
    user(function(user) {
      const { usergroup } = user || { usergroup: null }
      const usergroups = ['admin', 'support']

      setIsAdmin(usergroups.includes(usergroup))
    })
  }, [isAdmin])


  return (
    <>
      <DashboardMenuItem to={`${url}`} icon={MdDashboard}>
        <Translate>Dashboard</Translate>
      </DashboardMenuItem>

      <DashboardMenuDivider>
        <Translate>Training</Translate>
      </DashboardMenuDivider>

      <DashboardMenuItem to={`${url}/roadmap`} icon={MdTimeline}>
        <Translate>Roadmaps</Translate>
      </DashboardMenuItem>

      {/* <DashboardMenuItem to={`${url}/sets`} icon={MdSchool}>
        <Translate>Owned</Translate>
      </DashboardMenuItem>

      <DashboardMenuItem to={`${url}/store`} icon={MdStore}>
        <Translate>Store</Translate>
      </DashboardMenuItem> */}

      {/* <DashboardMenuDivider>
        <Translate>Need help?</Translate>
      </DashboardMenuDivider>

      <DashboardMenuItem to={`${url}/chat`} icon={MdChat}>
        <Translate>Chat us!</Translate>
      </DashboardMenuItem> */}

      { isAdmin ?
        <>
          <DashboardMenuDivider>
            <Translate>Administration</Translate>
          </DashboardMenuDivider>

          <DashboardMenuItem to={'/admin'} icon={MdSecurity}>
            <Translate>Admin dashboard</Translate>
          </DashboardMenuItem>
        </>
        : null }
    </>
  )
}

export default PlatformMenu
