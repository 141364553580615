import React from 'react'

import TextInput from './TextInput'

function EmailInput(props) {
  return (
    <TextInput type='email' { ...props } />
  )
}

export default EmailInput
