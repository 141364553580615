import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@chakra-ui/react'

import Translate from '../Translate'

const SubmitButton = ({ children, onClick: passClickEvent, ...props }) => {

  const [loading, setLoading] = useState(false)

  const handleClick = (e) => {
    setLoading(true)
    if (passClickEvent) passClickEvent(e, function() {
      setLoading(false)
    })
  }

  return (
    <Button type='submit' { ...props } onClick={handleClick} isLoading={loading}>
      {
        loading
          ? <Translate>Loading...</Translate>
          : children
      }
    </Button>
  )
}

SubmitButton.propTypes = {
  children: PropTypes.element,
  disabled: PropTypes.bool,
  onClick: PropTypes.func
}

export default SubmitButton
