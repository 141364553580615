import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Flex, Spacer, Tooltip, IconButton, HStack } from '@chakra-ui/react'
import { DeleteIcon } from '@chakra-ui/icons'
import EditorJs from 'react-editor-js'
import { debounce } from 'debounce'
import useStateCallback from '../../utils/useStateWithCallback'
import edjsHTML from 'editorjs-html'
import TOOLS from '../../utils/editorjsTools'
import Translate from '../../components/Translate'
import VideoJS from '../../components/sets/VideoPlayer'
import { useApiFetch } from '../../utils/api'
import SetContentContext from '../../contexts/SetContentContext'
import AwesomeDebouncePromise from 'awesome-debounce-promise'

function SetPage({ page, isEditable = false, remove, updateContent, canDeletePage }) {

  const { content: { pages, ready }, setCompleted } = useContext(SetContentContext)

  const { no, content } = pages[page]

  const [enableReInitialize, setEnableReInitialize] = useStateCallback(false)
  const [completeQueue, setCompleteQueue] = useState({})
  const api = useApiFetch()

  const data = {
    version: '2.22.2',
    timestamp: Math.floor(Date.now() / 1000),
    blocks: content.map(({ content: json, completed }) => ({ ...JSON.parse(json), completed })).filter(({ id }) => id)
  }

  const onEditorChange = (_api, { blocks }) => {
    if (isEditable) updateContent(no, blocks.map((content) => JSON.stringify(content)))
  }

  const edjsParser = edjsHTML({ video: ({ data: { url } }) => (url) })
  const parsed = edjsParser.parse(data).map((html, ix) => ({
    html,
    id: data.blocks[ix].id,
    type: data.blocks[ix].type,
    completed: data.blocks[ix].completed
  }))

  let html = []
  let wasHidden = false

  for ( let ix in parsed ) {
    ix = parseInt(ix)
    if (!wasHidden && (ix === 0 || (parsed[ix - 1] && (parsed[ix - 1].type !== 'video' || (parsed[ix - 1].type === 'video' && parsed[ix - 1].completed))))) {
      html = [ ...html, parsed[ix]]
    }
    else {
      wasHidden = true
    }
  }

  const request = (id) => api(`/set/complete/${id}`, {
    method: 'POST'
  })
    .catch(() => {})

  useEffect(async () => {
    if (ready) {
      for (let id in completeQueue) {
        if (!completeQueue[id]) {
          setCompleted(id)
          setCompleteQueue({ ...completeQueue, [id]: true })
          await AwesomeDebouncePromise(request, 100)(id)
        }
      }
    }
  }, [completeQueue])

  const listener = debounce(() => {
    html.filter(({ type, completed }) => type !== 'video' && !completed).forEach(({ id, type }) => {
      const element = document.querySelector(`#lc__${id}`)

      if (element) {
        const rect = element.getBoundingClientRect()
        const elemTop = rect.top
        const elemBottom = rect.bottom

        const isVisible = type === 'image' ? elemTop < window.innerHeight : elemTop >= 0 && elemBottom <= window.innerHeight
        const element_id = element.id.replace('lc__', '')
        if (isVisible && !(element_id in completeQueue)) setCompleteQueue({ ...completeQueue, [element_id]: false })
      }
    })
  }, 100, true)

  useEffect(() => {
    const playerListener = ({ target: element }) => {
      const element_id = element.id.replace('lc__', '')
      if (!(element_id in completeQueue)) setCompleteQueue({ ...completeQueue, [element_id]: false })
    }

    if (!isEditable) html.filter(({ type, completed }) => type === 'video' && !completed).forEach(({ id }) => {
      const element = document.querySelector(`#lc__${id}`)

      if (element && element.player) element.player.on('ended', playerListener)
    })

    if (!isEditable) window.addEventListener('scroll', listener)

    return () => {
      if (!isEditable) window.removeEventListener('scroll', listener)

      if (!isEditable) html.filter(({ type, completed }) => type === 'video' && !completed).forEach(({ id }) => {
        const element = document.querySelector(`#lc__${id}`)

        if (element && element.player) element.player.off('ended', playerListener)
      })
    }
  }, [html])

  useEffect(() => {
    // console.log('PAGE CHANGED, CALLING LISTENER')
    listener()
  }, [page])

  const handleRemove = () => {
    if (isEditable) setEnableReInitialize(true, () => {
      remove(no - 1).then(() => {
        setEnableReInitialize(false)
      })
    })
  }

  return (
    <Box>
      {
        !isEditable
          ? html.map(({ html: content, id, type }) =>
            type === 'video'
              ? <VideoJS options={{
                autoplay: false,
                controls: true,
                responsive: true,
                fluid: true,
                sources: {
                  src: content,
                  type: 'application/x-mpegURL',
                },
              }} id={`lc__${id}`} key={`set-comp-${id}`} />
              : <div key={`set-comp-${id}`} id={`lc__${id}`} className={`lc lc__${type}`} dangerouslySetInnerHTML={{ __html: content }} />
          )
          : <Box>
            <Flex>
              <HStack spacing={2}></HStack>
              <Spacer />
              {
                canDeletePage ?
                  <Tooltip label={<Translate>Delete page</Translate>}>
                    <IconButton onClick={handleRemove} icon={<DeleteIcon />} variant='brand-ghost'/>
                  </Tooltip>
                  : null
              }
            </Flex>
            <EditorJs enableReInitialize={enableReInitialize} data={data} onChange={onEditorChange} tools={TOOLS} />
          </Box>
      }
    </Box>
  )
}

SetPage.propTypes = {
  page: PropTypes.number,
  isEditable: PropTypes.bool,
  remove: PropTypes.func,
  updateContent: PropTypes.func,
  canDeletePage: PropTypes.bool
}

export default SetPage
