import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box, Center, Text, Heading, Link, useBreakpointValue, VStack, Stack } from '@chakra-ui/react'

import Translate from '../components/Translate'
import Logo from '../components/Logo'
import AuthForm from '../components/auth/AuthForm'
import UEInfo from '../components/UEInfo'
import { useCreateOrderProduct, useAuth } from '../utils/auth'

function Login({ form, authorize }) {
  const { auth } = useAuth()

  const [product, setProduct] = useState(null)

  const isMobile = useBreakpointValue({ base: true, md: false })

  const getProduct = useCreateOrderProduct()

  useEffect(async () => {
    if (!product) {
      const newProduct = await getProduct()
      setProduct( newProduct )
    }
  }, [ auth.newOrderProduct ])

  return (
    <Box w="100%" display={{ base: 'block', md: 'flex' }}>
      <Box display={{ base: 'none', md: 'block' }} w='30%' bg='brand.400' color='white' p={4} boxShadow='lg'>
        <Center h='100%'>
          <Logo src='logo-100' color='light'/>
        </Center>
      </Box>
      <VStack px={{ base: 10, md: 20, lg: 100, xl: 200 }} py={8} w={{ base: '100%', md: '70%' }} minH="100vh" align='center' justify='center'>
        <Box textAlign='center' w='100%'>
          {
            isMobile ?
              <Box mt={6}>
                <Center h='100%'>
                  <Logo src='logo-100'/>
                </Center>
              </Box>
              : null
          }
          <Box>
            {
              product
                ? <VStack spacing={4} my={6}>
                  <Heading as='h4' size='lg'>Aby kontynuować zakup, zaloguj się lub zarejestruj</Heading>
                  <Stack w={{ base: '100%', md: 'initial' }} position='relative' shadow='sm' direction={{ base: 'column', md: 'row'}} maxH={{ base: 'intial', md: '100px' }} align='flex-start' alignItems='stretch' bg='gray.50' borderRadius={4}>
                    <Box bg={ (isMobile ? 'linear-gradient(180deg, rgba(255, 255, 255, 0) 59.14%, #FFFFFF 100%),' : '') + `url(${product.image})`} h='100px' w={{ base: '100%', md: '150px' }} bgRepeat='no-repeat' bgSize={{ base: 'cover', md: 'contain' }} borderRadius={{ base: '4px 4px 0 0', md: '4px 0 0 4px'}} bgPos='center' />
                    <VStack justify='center' align={{ base: 'center', md: 'flex-start' }} p={4} pt={isMobile ? 2 : 4} >
                      <Heading as='h5' size='md'>{ product.title }</Heading>
                      <Text>
                        <strong>
                          <Translate>Price</Translate>:
                        </strong>
                        { ' '+ product.price.brutto.formatted }
                      </Text>
                    </VStack>
                  </Stack>
                </VStack>
                : null
            }
          </Box>
          <Box py={10}>
            {
              form === 'login' && authorize ?
                <Heading as='h3'><Translate>Sign in to authorize action</Translate></Heading>
                : form === 'login' ?
                  <Heading as='h3'><Translate>Sign in</Translate></Heading>
                  : <Heading as='h3'><Translate>Sign up</Translate></Heading>
            }
          </Box>

          <AuthForm form={form} authorize={authorize} />
          <Text my={4}>
            &copy; <Link href='https://lifesense.pl' isExternal>
              <Translate>Life Sense Foundation</Translate>
            </Link> 2021
          </Text>
        </Box>

        <UEInfo stealMargin />
      </VStack>
    </Box>
  )
}

Login.propTypes = {
  form: PropTypes.string,
  authorize: PropTypes.bool
}

export default Login
