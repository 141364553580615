import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import SetEditorContext from '../../contexts/SetEditorContext'
import DescriptionDialog from './DescriptionDialog'

function SetDescriptionDialog({ initialValue }) {
  const { isDescOpen: isOpen, onDescClose: onClose, handleDescriptionChange: handleChange } = useContext(SetEditorContext)

  return (
    <DescriptionDialog initialValue={initialValue} disclosure={{ isOpen, onClose, handleChange }} />
  )
}

SetDescriptionDialog.propTypes = {
  initialValue: PropTypes.string
}

export default SetDescriptionDialog
