import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@chakra-ui/react'

import { useApiFetch } from '../../utils/api'
import RoadmapSet from '../../components/sets/RoadmapSet'
// import RoadmapConnection from '../../components/sets/RoadmapConnection'

function Roadmap({ id, data }) {

  const api = useApiFetch()

  const [roadmap, setRoadmap] = useState(data)

  useEffect(() => {
    if (!data) api(`/roadmaps/${id}`)
      .then((res) => {
        setRoadmap(res)
      })
      .catch(({ status }) => {
        if (status === 404) setRoadmap([])
      })
  }, [])

  // const levels_count = {}
  // Object.keys(roadmap).forEach((level) => { levels_count[level] = roadmap[level].length })

  return (
    <Box>
      {/* {
          Object.keys(roadmap).map((level) => {
            const relations = roadmap[level]

            return (
              <Box key={`${levels_count[level - 1]}to${relations.length}-l${level}`}>
                { levels_count[level - 1] ? <RoadmapConnection type={`${levels_count[level - 1]}to${relations.length}`} /> : <></> }
                <Flex justify='space-around' key={'lvl-'+ level}>
                  {
                    relations.map(relation => (
                      <RoadmapSet data={relation} key={`r-${relation.id}-l${level}-o-${relation.order}`} />
                    ))
                  }
                </Flex>
              </Box>
            )
          })
        }) */}
      {
        Object.keys(roadmap).map((level) => {
          const relations = roadmap[level]

          return relations.map(relation => (
            <RoadmapSet data={relation} key={`r-${relation.id}-l${level}-o-${relation.order}`} />
          ))
        })
      }
    </Box>
  )
}

export default Roadmap

Roadmap.propTypes = {
  id: PropTypes.number,
  data: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        level: PropTypes.number,
        order: PropTypes.number,
        set: PropTypes.number
      })
    )
  )
}
