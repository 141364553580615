import React, { useContext, useEffect, useState } from 'react'
import { useHistory, Link as RouteLink } from 'react-router-dom'
import { VStack, Heading, Text, Box, SimpleGrid, Image, Flex, Icon, Divider, Button, Spacer, useBreakpointValue } from '@chakra-ui/react'
import { ArrowLeftIcon } from '@chakra-ui/icons'
import { MdAccountBalance, MdPayment } from 'react-icons/md'
import _ from 'lodash'
import { OrderContext } from '../../contexts/OrderContext'
import { useApiFetch } from '../../utils/api'
import Translate from '../../components/Translate'

function Summary() {
  const { data: { data, address, payment, shipping, shippingDetails } } = useContext(OrderContext)

  const api = useApiFetch()
  const history = useHistory()

  if (!address || !payment) history.replace('/platform/checkout')

  const [sumAddress, setSumAddress] = useState(null)
  const [sumPayment, setSumPayment] = useState(null)
  const [sumShipping, setSumShipping] = useState(null)
  const [isOrdering, setIsOrdering] = useState(false)

  useEffect(() => {
    if (!sumAddress && address) api('/client/address/' + address).then((response) => {
      setSumAddress(response)
    })
      .catch(() => {})

    if (!sumPayment && payment) api('/payments/methods/' + payment).then((response) => {
      setSumPayment(response)
    })
      .catch(() => {})

    if (!sumShipping && shipping) api('/shippings/' + shipping).then((response) => {
      setSumShipping(response)
    })
      .catch(() => {})
  }, [])

  const handleOrderClick = () => {
    setIsOrdering(true)

    api('/orders', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify({ products: data.products, discount: data.discount, total: data.total, address, payment, shipping, shippingDetails })
    })
      .then(({ uuid }) => {
        sessionStorage.removeItem('lifesense-basket')
        history.replace('/platform/orders/'+ uuid +'/payment')
      })
      .catch(() => {
        setIsOrdering(false)
      })
  }

  const isMobile = useBreakpointValue({ base: true, md: false })

  const { name, street, property_no, place_no, postcode, city, phone, is_company, tax_no } = sumAddress || { name: '', street: '', property_no: '', place_no: '', postcode: '', city: '', phone: '', is_company: false, tax_no: '' }
  const { name: payment_name, image: payment_logo } = sumPayment || { name: '', image: '' }
  const { name: shipping_name, logo: shipping_logo, method } = sumShipping || { name: '', logo: '' }

  return (
    <VStack>
      <Heading size='xl'>
        <Translate>Summary</Translate>
      </Heading>
      <Text pb={8}>
        <Translate>Step 3. Verify the correctness of the entered data and selected products in the basket. In the next step you will go to the payment process.</Translate>
      </Text>
      <SimpleGrid columns={{ base: 1, md: 2 }} w='100%' px={6}>
        <VStack spacing={8} alignItems='flex-start'>
          <Box>
            <Heading size='md' mb={3}>
              <Translate>Buyer&apos;s address</Translate>
            </Heading>
            <Text>{ name }</Text>
            <Text>{`${street} ${property_no}`+ (place_no ? '/' + place_no : '')}</Text>
            <Text>{`${postcode} ${city}`}</Text>
            <Text>{`${new String(phone).replace(/(?!^)(?=(?:\d{3})+(?:\.|$))/gm, ' ')}`}</Text>
            {
              is_company ?
                <Text fontSize={14}>
                  <Translate>VAT No.</Translate>: {`${tax_no}`}
                </Text>
                : null
            }
          </Box>
          {
            shipping
              ? <Box>
                <Heading size='md' mb={3}>
                  <Translate>Shipping</Translate>
                </Heading>
                <Flex alignItems='center'>
                  <Image maxW={50} src={shipping_logo} />
                  <Text ml={4} pl={4} borderLeft='.4px solid' h='100%' display='block'>{ shipping_name }</Text>
                </Flex>
                {
                  new Array(shippingDetails).map((details) => {

                    switch(method){
                    case 'address' :

                      return (
                        <Box key='this-is-a-very-unique-key' mt={4}>
                          <Text fontWeight='bold'>{ details.name }</Text>
                          <Text>{`${details.street} ${details.property_no}`+ (details.place_no ? '/' + details.place_no : '')}</Text>
                          <Text>{`${details.postcode} ${details.city}`}</Text>
                          <Text>{`${new String(details.phone).replace(/(?!^)(?=(?:\d{3})+(?:\.|$))/gm, ' ')}`}</Text>
                          {
                            details.is_company ?
                              <Text fontSize={14}>
                                <Translate>VAT No.</Translate>: {`${details.tax_no}`}
                              </Text>
                              : null
                          }
                        </Box>
                      )

                    case 'inpost_parcel_lockers' :

                      return (
                        <Box key='this-is-a-very-unique-key' mt={4}>
                          <Text fontWeight='bold'>{ details.name }</Text>
                          <Text>{ details.address.line1 }</Text>
                          <Text>{ details.address.line2 }</Text>
                        </Box>
                      )

                    case 'pp_pickup' :

                      return (
                        <Box key='this-is-a-very-unique-key' mt={4}>
                          <Text fontWeight='bold'>{ details.name }</Text>
                          <Text>{ details.street }</Text>
                          <Text>{ details.zipCode + ' ' + details.city }</Text>
                        </Box>
                      )

                    }
                  })
                }
              </Box>
              : null
          }
          <Box>
            <Heading size='md' mb={3}>
              <Translate>Payment method</Translate>
            </Heading>
            <Flex alignItems='center'>
              { payment_logo ? <Image src={payment_logo} h={25} maxW={50} objectFit='contain' /> : <Icon fontSize='2em' w={50} as={MdAccountBalance} /> }
              <Text ml={4} pl={4} borderLeft='.4px solid' h='100%' display='block'>
                <Translate>{ payment_name }</Translate>
              </Text>
            </Flex>
          </Box>
        </VStack>
        <VStack mt={isMobile ? 8 : 0} spacing={8} alignItems={isMobile ? 'flex-start' : 'flex-end'}>
          <VStack spacing={4} alignItems='flex-end'>
            {
              data && data.products ? data.products.map(({ product_id, title, quantity, sum_price, price }) => (
                <Box key={'products-'+product_id} textAlign='right'>
                  <Text>{ title } - {price.brutto.formatted} &times; { quantity }: { sum_price.brutto.formatted }</Text>
                  {
                    data.discount.products[product_id] ? <Text fontSize='sm'>
                      <Translate>Discount</Translate>
                      <span> { data.discount.products[product_id].percentage }% - { data.discount.products[product_id].name }</span>
                    </Text> : null
                  }
                </Box>
              ))
                : null
            }
          </VStack>
          <Divider />
          <VStack spacing={3} alignItems={isMobile ? 'flex-start' : 'flex-end'}>
            {
              data && data.discount && typeof data.discount.price === 'object'
                ? <Text mb={1}>
                  <Translate>Discount</Translate> ({ data.discount.name }): { data.discount.price.formatted }
                </Text>
                : null
            }
            {
              shipping && sumShipping
                ? <Text>
                  <Translate>Shipping</Translate>: {data.shippings[_.findIndex(data.shippings, { id: sumShipping.id })].price.formatted}
                </Text>
                : null
            }
            {
              data
                ? <>
                  <Text>
                    <Translate>Netto</Translate>: { data.netto.formatted }
                  </Text>
                  <Text>
                    <Translate>VAT</Translate>: { data.tax.formatted }
                  </Text>
                  <Text fontSize='1.3em'>
                    <Translate>Total</Translate>: { data.total.formatted }
                  </Text>
                </>
                : null
            }
          </VStack>
        </VStack>
      </SimpleGrid>
      <Flex w={'100%'} pt={4}>
        <RouteLink to='/platform/checkout'>
          <Button variant='brand-ghost' leftIcon={<ArrowLeftIcon />}>
            <Translate>Back to checkout</Translate>
          </Button>
        </RouteLink>
        <Spacer />
        <Button isLoading={!sumAddress || !sumPayment || (shipping && !sumShipping) || isOrdering} onClick={handleOrderClick} leftIcon={<MdPayment />}>
          <Translate>Order</Translate>
        </Button>
      </Flex>
    </VStack>
  )
}

export default Summary
