import React, { useState , useContext, useEffect } from 'react'
import { Link as RouteLink } from 'react-router-dom'
import { Box, Text, Button, Flex, VStack, Spacer, Heading, Table, Thead, Tbody, Tr, Td, IconButton, useBreakpointValue } from '@chakra-ui/react'
import { ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons'
import Loader from '../../components/Loader'
import { basketContext } from '../../utils/contexts/basket'
import Translate from '../../components/Translate'
import { useApiFetch } from '../../utils/api'
import { MdCancel } from 'react-icons/md'
import QuantityInput from '../../components/inputs/QuantityInput'
import ActiveLink from '../../components/ActiveLink'

function Cart() {

  const api = useApiFetch()
  const { state: basket, removeAllItems, addItem, removeItem } = useContext(basketContext)
  const [items, setItems] = useState([])
  const [loaded, setLoaded] = useState(false)
  const isMobile = useBreakpointValue({ base: true, md: false })

  useEffect(() => {
    setLoaded(false)

    if (Object.keys(basket).length) {
      api('/store/cart', {
        method: 'POST',
        body: JSON.stringify(basket),
        headers: {
          'Content-type': 'application/json'
        }
      })
        .then((response) => {
          setItems(response)
          setLoaded(true)
        })
        .catch(({ status }) => {
          if (status === 404) {
            setItems([])
            setLoaded(true)
          }
        })
    }
    else {
      setItems([])
      setLoaded(true)
    }

  }, [basket])

  return (
    <VStack>
      <Heading>
        <Translate>Your cart</Translate>
      </Heading>
      <Text pb={8}>
        <Translate>Step 1. Add products to your cart.</Translate>
      </Text>
      <Box w={'100%'}>
        <Loader loaded={loaded}>
          {
            items.length ?
              <VStack>
                <Table w='100%'>
                  {
                    !isMobile ?
                      <Thead>
                        <Tr>
                          <Td>
                            <Translate>Name</Translate>
                          </Td>
                          <Td>
                            <Translate>Price</Translate>
                          </Td>
                          <Td isNumeric>
                            <Translate>Quantity</Translate>
                          </Td>
                          <Td>
                            <Translate>Sum</Translate>
                          </Td>
                          <Td>
                            <Translate>Remove</Translate>
                          </Td>
                        </Tr>
                      </Thead>
                      : null
                  }
                  <Tbody>
                    {
                      !isMobile ?
                        items.map(({ product_id, title, price, sum_price, max_per_basket: max, min_per_basket: min }) => (
                          <Tr key={'prod-it-'+ product_id}>
                            <Td>{ title }</Td>
                            <Td>{ price.brutto.formatted }</Td>
                            <Td isNumeric display='flex' justifyContent='flex-end'>
                              <QuantityInput onAdd={() => { addItem(product_id) }} disabledAdd={max !== 0 && basket[product_id] >= max} onMinus={() => { min !== 0 && basket[product_id] <= min ? removeAllItems(product_id) : removeItem(product_id) }} value={basket[product_id]} />
                            </Td>
                            <Td>{ sum_price.brutto.formatted }</Td>
                            <Td>
                              <IconButton
                                colorScheme="brand"
                                aria-label="Remove item"
                                size="sm"
                                variant='brand-ghost'
                                icon={<MdCancel />}
                                onClick={() => { removeAllItems(product_id) }}
                              />
                            </Td>
                          </Tr>
                        ))
                        : items.map(({ product_id, title, price, sum_price, max_per_basket: max, min_per_basket: min }, ix) => (
                          <>
                            <Tr key={'prod-it-title-'+ product_id}>
                              <Td textAlign='center' colSpan='2'>{ (ix + 1) + '. ' + title }</Td>
                            </Tr>
                            <Tr key={'prod-it-price-'+ product_id}>
                              <Td>
                                <Translate>Price</Translate>
                              </Td>
                              <Td textAlign='right'>{ price.brutto.formatted }</Td>
                            </Tr>
                            <Tr key={'prod-it-qty-'+ product_id}>
                              <Td>
                                <Translate>Quantity</Translate>
                              </Td>
                              <Td display='flex' justifyContent='flex-end'>
                                <QuantityInput onAdd={() => { addItem(product_id) }} disabledAdd={max !== 0 && basket[product_id] >= max} onMinus={() => { min !== 0 && basket[product_id] <= min ? removeAllItems(product_id) : removeItem(product_id) }} value={basket[product_id]} />
                              </Td>
                            </Tr>
                            <Tr key={'prod-it-sum-'+ product_id}>
                              <Td>
                                <Translate>Sum</Translate>
                              </Td>
                              <Td textAlign='right'>
                                { sum_price.brutto.formatted }
                              </Td>
                            </Tr>
                            <Tr key={'prod-it-remove-'+ product_id}>
                              <Td>
                                <Translate>Remove</Translate>
                              </Td>
                              <Td textAlign='right'>
                                <IconButton
                                  colorScheme="brand"
                                  aria-label="Remove item"
                                  size="sm"
                                  variant='brand-ghost'
                                  icon={<MdCancel />}
                                  onClick={() => { removeAllItems(product_id) }}
                                />
                              </Td>
                            </Tr>
                          </>
                        ))
                    }
                  </Tbody>
                </Table>
              </VStack>
              :
              <Text py={10} textAlign='center'>
                <Translate>No items added to cart.</Translate>
              </Text>
          }
        </Loader>
      </Box>
      <Flex w={'100%'}>
        <RouteLink to='/platform/roadmap'>
          <Button variant='brand-ghost' leftIcon={<ArrowLeftIcon />}>
            <Translate>Back to roadmaps</Translate>
          </Button>
        </RouteLink>
        <Spacer />
        <ActiveLink to={'/platform/checkout'} isActive={loaded && items.length !== 0} >
          <Button isLoading={!loaded} disabled={!loaded || (loaded && items.length === 0)} rightIcon={<ArrowRightIcon />}>
            <Translate>Next</Translate>
          </Button>
        </ActiveLink>
      </Flex>
    </VStack>
  )
}

export default Cart
