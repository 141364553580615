import React, { useContext } from 'react'
import { MdPerson, MdSettings, MdShoppingBasket } from 'react-icons/md'
import { Menu, MenuButton, MenuItem, MenuDivider, MenuGroup, Button, MenuList, Text, Icon, Box } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Translate from '../Translate'
import { basketContext } from '../../utils/contexts/basket'

function DashboardHeaderUserMenuTT({ children, logout }) {

  const history = useHistory()
  const { t } = useTranslation()
  const { state: basket } = useContext(basketContext)

  return (
    <Menu>
      <MenuButton as={Button} variant='brand-ghost'>
        <Box display='flex' w='100%' justifyContent='center' alignItems='center'>
          <Icon as={MdPerson} w={6} h={6} mr={2}/>
          <Text>{ children }</Text>
        </Box>
      </MenuButton>
      <MenuList>
        <MenuGroup title={t('Account')}>
          {
            Object.keys(basket).length ?
              <MenuItem icon={<MdShoppingBasket size='1.4em' />} onClick={() => history.replace({ pathname: '/platform/cart' })}>
                <Translate>Your cart</Translate>
              </MenuItem>
              : null
          }
          {/* <MenuItem icon={<MdAccountBox size='1.4em' />} onClick={() => history.replace({ pathname: '/platform/user' })}>
            <Translate>Your profile</Translate>
          </MenuItem>
          <MenuItem icon={<MdShoppingCart size='1.4em' />} onClick={() => history.replace({ pathname: '/platform/orders' })}>
            <Translate>Orders &amp; payments</Translate>
          </MenuItem> */}
          <MenuItem icon={<MdSettings size='1.4em' />} onClick={() => history.replace({ pathname: '/platform/settings' })}>
            <Translate>Settings</Translate>
          </MenuItem>
        </MenuGroup>
        <MenuDivider />
        <MenuItem onClick={logout} >
          <Translate>Logout</Translate>
        </MenuItem>
      </MenuList>
    </Menu>
  )
}

export default function DashboardHeaderUserMenu(props) {
  return (
    <React.Suspense fallback='loading'>
      <DashboardHeaderUserMenuTT {...props} />
    </React.Suspense>
  )
}

DashboardHeaderUserMenuTT.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  logout: PropTypes.func
}
