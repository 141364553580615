import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, createStandaloneToast } from '@chakra-ui/react'
import { MdShoppingCart, MdDone } from 'react-icons/md'
import { useHistory } from 'react-router-dom'
import Translate from '../Translate'
import { basketContext } from '../../utils/contexts/basket'
import { useApiFetch } from '../../utils/api'

function AddToBasketButton({ item }) {

  const history = useHistory()

  const api = useApiFetch()

  const { addItem: addToBasket, state: basket } = useContext(basketContext)

  const [product, setProduct] = useState(item)

  useEffect(() => {
    if (typeof product === 'number') {
      api('/store/product/'+ product).then((response) => {
        setProduct(response[0])
      })
        .catch(() => {})
    }
  }, [])

  const click = function() {
    const { min_per_basket, product_id } = product

    addToBasket(product_id, min_per_basket === 0 ? 1 : min_per_basket)

    history.replace('/platform/cart')

    const toast = createStandaloneToast()

    if (!toast.isActive('add-to-basket')) {
      toast({
        id: 'add-to-basket',
        title: <Translate>Dodano do koszyka!</Translate>,
        status: 'success',
        duration: 1000,
        isClosable: true,
      })
    }
  }
  useEffect(() => {
    console.log(product)
  }, [product])

  return (
    product && product.product_id ?
      <Button disabled={basket[product.id] >= product.max_per_basket} leftIcon={basket[product.id] >= product.max_per_basket ? <MdDone /> : <MdShoppingCart />} colorScheme="brand" onClick={click}>
        {
          basket[product.id] >= product.max_per_basket ?
            <Translate>Added to cart</Translate>
            : <Translate>{ Object.keys(basket).length ? 'Add to cart' : 'Buy' }</Translate>

        }

      </Button>
      : <Button isLoading={true}>Buy</Button>
  )
}

export default AddToBasketButton

AddToBasketButton.propTypes = {
  item: PropTypes.oneOfType([
    PropTypes.shape({
      product_id: PropTypes.number,
      min_per_basket: PropTypes.number,
      max_per_basket: PropTypes.number
    }),
    PropTypes.number
  ])
}
