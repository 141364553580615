import Paragraph from '@editorjs/paragraph'
import List from '@editorjs/list'
import LinkTool from '@editorjs/link'
import Image from '@editorjs/image'
import Header from '@editorjs/header'
import Quote from '@editorjs/quote'
import i18n from './i18n'
import { apiFetch } from './api'
import Video from './ejs-tools/Video'
import 'video.js/dist/video-js.css'
import '@videojs/themes/dist/forest/index.css'

export default {
  paragraph: Paragraph,
  list: List,
  linkTool: LinkTool,
  image: {
    class: Image,
    config: {
      endpoints: {
        byFile: process.env.REACT_APP_API + '/upload/set-image',
        byUrl: process.env.REACT_APP_API + '/upload/set-image'
      },
      uploader: {
        uploadByFile: (file) => new Promise((resolve) => {

          const body = new FormData()
          body.append('image', file)

          apiFetch(null)('/upload/set-image', {
            method: 'POST',
            body
          })
            .then((response) => {
              resolve(response)
            })
            .catch(() => {
              resolve({ success: 0 })
            })
        })
      },
      captionPlaceholder: i18n.t('Image caption'),
      buttonContent: i18n.t('Select image')
    }
  },
  header: Header,
  quote: Quote,
  video: Video
}
