import React, { useState, useEffect } from 'react'
import { Link, useParams, Redirect } from 'react-router-dom'
import { Box, Heading, Text, useColorModeValue, Progress, Spacer, Flex, Button, useBreakpointValue } from '@chakra-ui/react'
import { ArrowBackIcon, ArrowForwardIcon, CheckIcon } from '@chakra-ui/icons'
import { useCountUp } from 'use-count-up'
import { useHistory } from 'react-router'
// import _ from 'lodash'

import { useApiFetch } from '../../utils/api'
import Loader from '../../components/Loader'
import Translate from '../../components/Translate'
import SetLearning from '../../components/sets/SetLearning'
import SetContentContext from '../../contexts/SetContentContext'

function Set() {
  const { slug } = useParams()
  const api = useApiFetch()
  const [content, setContent] = useState({ ready: false, pages: [ { content: [] }], modified: Date.now() })
  const [page, setPage] = useState(0)
  const [allowForward, setAllowForward] = useState(false)
  const [error, setError] = useState(null)
  const [progress, setProgress] = useState(0)
  const history = useHistory()

  const contentBoxColor = useColorModeValue('white', 'gray.700')

  useEffect(() => {

    api(`/set/${slug}`)
      .then((response) => {
        setContent({ modified: Date.now(), ready: true, ...response })
        setTimeout(() => setProgress(response.progress.percent), 500)
      })
      .catch(async (err) => {
        setError({ message: err.statusText, status: err.status })
      })
  }, [])

  useEffect(() => {
    const newAllowForward = !content.pages[page].content.map(({ completed }) => completed).includes(false)
    // console.log('ALLOW FORWARD', {allowForward}, content.pages[page].content.map(({ completed }) => completed), newAllowForward)

    setAllowForward(newAllowForward)
  }, [page])

  const isMobile = useBreakpointValue({ base: true, md: false })

  let uniquePages = []
  content.pages.forEach(({ content }) => {
    uniquePages = [...uniquePages, ...content]
  })

  const { value: progressVal } = useCountUp({
    isCounting: !!progress,
    end: Math.floor((uniquePages.map(({ completed }) => completed).filter((bool) => bool).length / uniquePages.map(({ completed }) => completed).length) * 100),
    duration: 0.2,
  })

  const nextPage = () => setPage(content && content.pages.length !== page + 1 ? page + 1 : page)
  const prevPage = () => setPage(page ? page - 1 : 0)

  const handleFinish = () => {
    history.replace('/platform/roadmap')
  }

  useEffect(() => {
    // console.log('CONTENT', {allowForward}, content.pages[page].content.map(({ completed }) => completed), !content.pages[page].content.map(({ completed }) => completed).includes(false))
    setAllowForward(!content.pages[page].content.map(({ completed }) => completed).includes(false))
  }, [content])

  const setCompleted = (id) => {
    if (content.ready) {
      const newContent =  { ...content, modified: Date.now() }

      for (let page_ix in newContent.pages){
        for (let component_ix in newContent.pages[page_ix].content) {
          const component_content = JSON.parse(newContent.pages[page_ix].content[component_ix].content)
          if (component_content.id === id) {
            newContent.pages[page_ix].content[component_ix].completed = true
            break
          }
        }
      }

      setContent(newContent)
    }
  }

  return (
    !error
      ? <Loader loaded={!!content.pages}>
        <Box w={{ base: '100%', md: '90%', lg: '70%' }} ml={{ base: '0%', md: '5%', lg: '15%' }}>
          <Box>
            <Flex direction={{ base: 'column', md: 'row'}}>
              <Heading as="h1" size='lg' mb={6} textAlign={{ base: 'center', md: 'left' }}>{ content.title }</Heading>
              {
                !isMobile
                  ? <>
                    <Spacer />
                    <Link to='/platform'>
                      <Button leftIcon={<ArrowBackIcon />} colorScheme="brand" variant="brand-ghost">
                        <Translate>Back</Translate>
                      </Button>
                    </Link>
                  </>
                  : null
              }
            </Flex>
            <Progress mb={2} value={progressVal} colorScheme='brand' height={1} />
          </Box>
          <Box p={10} borderRadius={'1.25rem'} bgColor={contentBoxColor} shadow='2xl'>
            {
              content.pages
                ? <SetContentContext.Provider value={{ slug, content, setCompleted }}>
                  <SetLearning page={page} />
                </SetContentContext.Provider>
                : null
            }

          </Box>
          <Flex mt={4}>
            <Button isDisabled={!page} onClick={prevPage} leftIcon={<ArrowBackIcon />} colorScheme="brand" variant="brand-ghost">
              <Translate>Back</Translate>
            </Button>
            <Spacer />
            {
              !(content.pages && content.pages.length !== page + 1)
                ? <Button isDisabled={!allowForward} onClick={handleFinish} leftIcon={<CheckIcon />} colorScheme="brand" variant="solid">
                  <Translate>Finish</Translate>
                </Button>
                : <Button isDisabled={!allowForward} onClick={nextPage} rightIcon={<ArrowForwardIcon />} colorScheme="brand" variant="solid">
                  <Translate>Next</Translate>
                </Button>
            }
          </Flex>
        </Box>
      </Loader>
      : (
        error.status === 403
          ? <Redirect to='/platform/roadmap' />
          : <Box w={{ base: '100%', md: '90%', lg: '70%' }} ml={{ base: '0%', md: '5%', lg: '15%' }} p={[10]} borderRadius={'1.25rem'} bgColor={contentBoxColor} shadow='2xl'>
            <Heading>
              <Translate>Error loading set</Translate>
            </Heading>
            <Text>{ error.message }</Text>
          </Box>
      )
  )
}

export default Set

