import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useColorModeValue, Box, Heading, Text, IconButton, AlertDialog, AlertDialogOverlay, AlertDialogBody, AlertDialogContent, AlertDialogHeader, AlertDialogFooter, Button, createStandaloneToast } from '@chakra-ui/react'
import { CheckIcon, DeleteIcon } from '@chakra-ui/icons'

import { useApiFetch } from '../../utils/api'
import Translate from '../Translate'

function AddressInput({ onClick, selected, input, refresh, data: { id, name, street, property_no, place_no, postcode, city, phone, is_company, tax_no } }) {
  const adddressesBgColor = useColorModeValue(selected ? 'brand.400' : 'gray.200', selected ? 'gray.600' : 'gray.800')
  const adddressesBgHoverColor = useColorModeValue(selected ? 'brand.400' : 'gray.100', selected ? 'gray.600' : 'gray.900')
  const fontColor = useColorModeValue(selected ? 'white' : 'gray.800', selected ? 'white' : 'white')

  const handleRefresh = () => refresh()

  const handleClick = () => onClick ? onClick(id) : null

  return (
    <Box key={'addr-'+ id} bg={adddressesBgColor} shadow='sm' position='relative' p={4} borderRadius='15px' transition='.2s' cursor='pointer' _hover={{ bg: adddressesBgHoverColor }} onClick={handleClick} color={fontColor}>
      {
        selected && input ?
          <>
            <input type='hidden' name={input.name} value={input.value} />
            <CheckIcon position='absolute' right={15} bottom={15} color={fontColor} w={5} h={5} />
          </>
          : null
      }
      <Heading as='h6' fontSize={16}>{ name }</Heading>
      <Text fontSize={14}>{`${street} ${property_no}`+ (place_no ? '/' + place_no : '')}</Text>
      <Text fontSize={14}>{`${postcode} ${city}`}</Text>
      <Text fontSize={14}>{`${new String(phone).replace(/(?!^)(?=(?:\d{3})+(?:\.|$))/gm, ' ')}`}</Text>
      {
        is_company ?
          <Text fontSize={14}>
            <Translate>VAT No.</Translate>: {`${tax_no}`}
          </Text>
          : null
      }
      { !input ? <RemoveAddressButton id={id} name={name} refresh={handleRefresh} /> : null }
    </Box>
  )
}

AddressInput.propTypes = {
  selected: PropTypes.bool,
  refresh: PropTypes.func,
  data: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    street: PropTypes.string,
    property_no: PropTypes.string,
    place_no: PropTypes.string,
    postcode: PropTypes.string,
    city: PropTypes.string,
    phone: PropTypes.string,
    is_company: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.number
    ]),
    tax_no: PropTypes.string
  }),
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ])
  }),
  onClick: PropTypes.func
}

export default AddressInput


function RemoveAddressButton({ id, name, refresh }) {
  const [isOpen, setIsOpen] = useState(false)
  const onClose = () => setIsOpen(false)
  const cancelRef = React.useRef()

  const api = useApiFetch()

  const toast = createStandaloneToast()

  const handleDelete = () => {
    api(`/client/address/${id}`, {
      method: 'DELETE'
    })
      .then(() => {
        onClose()
        refresh()
        toast({
          title: <Translate>Deleted!</Translate>,
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
      })
      .catch(() => {})
  }

  return (
    <>
      <IconButton icon={<DeleteIcon />} size='sm' position='absolute' right='15px' bottom='15px' onClick={() => setIsOpen(true)} />

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              <Translate>Delete address</Translate>
            </AlertDialogHeader>

            <AlertDialogBody>
              <Translate options={{ name }}>{'Are you sure you want to remove address to {{name}}?'}</Translate>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                <Translate>Cancel</Translate>
              </Button>
              <Button colorScheme="red" onClick={handleDelete} ml={3}>
                <Translate>Delete</Translate>
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

RemoveAddressButton.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  refresh: PropTypes.func
}

