import React, { Suspense } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Box, Text, Link, Center, Stack, InputLeftElement, Icon } from '@chakra-ui/react'
import { MdEmail, MdLock } from 'react-icons/md'

import EmailInput from '../inputs/EmailInput'
import PasswordInput from '../inputs/PasswordInput'

function LoginAuthFormTT({ errors, passInput }) {
  const { t } = useTranslation()
  const { email: errEmail, password: errPassword, recaptcha: errRecaptcha } = errors || { email: '', passoword: '', recaptcha: '' }

  return (
    <Stack spacing='16px'>
      <EmailInput
        name='email'
        label={ t('E-mail') }
        error={ t(errEmail) }
        onInput={(e) => passInput('email', e)}
        innerLabel
        inputLeft={
          <InputLeftElement pointerEvents="none">
            <Icon as={MdEmail} color="gray.300" />
          </InputLeftElement>
        }
      />
      <PasswordInput
        name='password'
        label={ t('Password') }
        error={ t(errPassword) }
        onInput={(e) => passInput('password', e)}
        innerLabel
        inputLeft={
          <InputLeftElement pointerEvents="none">
            <Icon as={MdLock} color="gray.300" />
          </InputLeftElement>
        }
      />
      {
        errRecaptcha
          ? <Center>
            <Text color='red.400'>{ errRecaptcha }</Text>
          </Center>
          : null
      }
      <Box>
        <Text lineHeight={1.4} fontSize={14} color='gray.400'>
          { t('This site is protected by reCAPTCHA and the Google') }
        </Text>
        <Text lineHeight={1.4} fontSize={14} color='gray.400'>
          <Link href="https://policies.google.com/privacy" rel="noreferrer" target="_blank">{ t('Privacy policy') }</Link> { t('and') } <Link href="https://policies.google.com/terms" rel="noreferrer" target="_blank">{ t('Terms of service') }</Link> { t('apply.') }
        </Text>
      </Box>
    </Stack>
  )
}

LoginAuthFormTT.propTypes = {
  errors: PropTypes.shape({
    email: PropTypes.string,
    password: PropTypes.string,
  }),
  passInput: PropTypes.func
}

export default function LoginAuthForm(props) {
  return (
    <Suspense fallback='loading'>
      <LoginAuthFormTT { ...props }/>
    </Suspense>
  )
}
