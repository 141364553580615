import React, { useReducer } from 'react'
import PropTypes from 'prop-types'

export const basketContext = React.createContext()

function reducer(state, { type, payload: { item, qty } }) {
  const newState = {...state}

  switch(type) {
  case 'add_item' :

    newState[item] = item in newState ? newState[item] + qty : qty
    break

  case 'remove_item' :

    if (newState[item] === 1) {
      delete newState[item]
    }
    else {
      newState[item] = newState[item] - 1
    }

    break

  case 'remove_all_items' :
    delete newState[item]

    break

  default:
    break
  }

  sessionStorage.setItem('lifesense-basket', JSON.stringify(newState))
  return newState
}

export function BasketContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, sessionStorage.getItem('lifesense-basket') ? JSON.parse(sessionStorage.getItem('lifesense-basket')) : {})

  const addItem = (item, qty = 1) => { dispatch({ type: 'add_item', payload: { item, qty } }) }
  const removeItem = (item) => { dispatch({ type: 'remove_item', payload: { item } }) }
  const removeAllItems = (item) => { dispatch({ type: 'remove_all_items', payload: { item } }) }

  return (
    <basketContext.Provider value={{
      addItem,
      removeItem,
      removeAllItems,
      state
    }}>
      { children }
    </basketContext.Provider>
  )
}

BasketContextProvider.propTypes = {
  children: PropTypes.node
}
