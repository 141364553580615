import React from 'react'
import { Box, Heading, VStack, HStack } from '@chakra-ui/react'

import Translate from '../../components/Translate'
import TransferAccept from '../../components/buttons/TransferAccept'

function Orders() {
  return (
    <VStack spacing={4} align='flex-start'>
      <Heading>
        <Translate>Orders</Translate>
      </Heading>
      <Box>
        <HStack spacing={2}>
          <TransferAccept />
        </HStack>
      </Box>
    </VStack>
  )
}

export default Orders
