import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const ActiveLink = ({ children, isActive, ...props }) => isActive ?  (
  <Link {...props}>
    { children }
  </Link>
) : children

ActiveLink.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.string,
    PropTypes.number
  ]),
  isActive: PropTypes.bool
}

export default ActiveLink

