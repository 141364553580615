import React, { useContext, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { HStack, Center, Image, AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogOverlay, AlertDialogHeader, AlertDialogFooter, Button } from '@chakra-ui/react'
import Translate from '../Translate'
import SetEditorContext from '../../contexts/SetEditorContext'
import FileUploadInput from '../inputs/FileUploadInput'

function SetImageDialog({ initialValue }) {
  const [value, setValue] = useState(initialValue || '')
  const { isImgOpen: isOpen, onImgClose: onClose, handleImageChange: handleChange } = useContext(SetEditorContext)
  const cancelRef = useRef()

  const handleClick = () => {
    handleChange(value)
    onClose()
  }

  const handleNewImage = ([{ file, uri }]) => {
    setValue({ file, uri })
  }

  const onDialogClose = () => {
    setValue(initialValue || '')
    onClose()
  }

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onDialogClose}
        autoFocus
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              <Translate>Change image</Translate>
            </AlertDialogHeader>

            <AlertDialogBody>
              <Center>
                <FileUploadInput
                  accept={'image/*'}
                  onChange={handleNewImage}
                  align='center'
                />
              </Center>
              <Image src={typeof value === 'object' ? value.uri : value} maxH={200} w={'100%'} objectFit='contain' mt={4} />
            </AlertDialogBody>

            <AlertDialogFooter>
              <HStack spacing={2}>
                <Button ref={cancelRef} onClick={onDialogClose} ml={3} variant='brand-ghost'>
                  <Translate>Cancel</Translate>
                </Button>
                <Button onClick={handleClick}>
                  <Translate>Change</Translate>
                </Button>
              </HStack>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

SetImageDialog.propTypes = {
  initialValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      file: PropTypes.object,
      uri: PropTypes.string
    })
  ])
}

export default SetImageDialog
