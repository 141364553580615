import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Flex, Text, Icon, useColorModeValue } from '@chakra-ui/react'
import { Link as RouteLink, useLocation } from 'react-router-dom'

import DashboardCtx from '../../utils/contexts/dashboard'

function DashboardMenuItem({ to, children, icon, onClick }) {

  const { pathname } = useLocation()
  const dashboard = useContext(DashboardCtx)

  const color = useColorModeValue(
    pathname === to ? 'brand.400' : 'gray.400',
    pathname === to ? 'brand.50' : 'gray.300'
  )

  const passOnClick = () => {
    dashboard.handleDrawerClose()
    if (!to && onClick) onClick()
  }

  return (
    <Flex onClick={passOnClick} px={4} align='center' borderRadius={3} _hover={{ bg: 'rgba(20, 20, 20, .1)', cursor: 'pointer' }} transition='.1s ease-in'>
      { icon ? <Icon as={icon} fontSize={'1.4em'} color={color} transition='.1s ease-in' /> : null }
      <Text color={color} fontWeight='medium' my={2} ml={2} fontSize='lg' transition='.1s ease-in' >{ children }</Text>
    </Flex>
  )
}

function DashboardMenuItemUrlWrapper(props) {
  return (
    props.to ?
      <RouteLink to={props.to}>
        <DashboardMenuItem { ...props } />
      </RouteLink>
      :
      <DashboardMenuItem { ...props } />
  )
}

DashboardMenuItem.propTypes = {
  to: PropTypes.string,
  icon: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.arrayOf(PropTypes.string)
  ]),
  onClick: PropTypes.func
}

DashboardMenuItemUrlWrapper.propTypes = {
  to: PropTypes.string
}

export default DashboardMenuItemUrlWrapper

