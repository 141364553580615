import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { SimpleGrid, VStack, Image, Text, Box } from '@chakra-ui/react'
import { useAuth } from '../utils/auth'

function UEInfo({ stealMargin }) {

  const { auth } = useAuth()

  const containerRef = useRef(null)

  return (
    <Box ref={containerRef} mb={stealMargin && containerRef.current && !auth.newOrderProduct ? (containerRef.current.offsetHeight * -1) + 'px !important' : null}>
      <VStack spacing={2}>
        <SimpleGrid columns={{ base: 1, md: 2, xl: 4 }} py={5} spacing={{ base: 5, xl: 10 }}>
          <Image objectFit='contain' src='/img/ue/fe.png' alt='Fundusze Europejskie | Program regionalny' height={{ base: '50px', lg: '60px', xl: '70px' }} />
          <Image objectFit='contain' src='/img/ue/rp.png' alt='Rzeczpospolita Polska' height={{ base: '50px', lg: '60px', xl: '70px' }} />
          <Image objectFit='contain' src='/img/ue/lubelskie.png' alt='Województwo Lubelskie' height={{ base: '50px', lg: '60px', xl: '70px' }} />
          <Image objectFit='contain' src='/img/ue/ue_efs.png' alt='Unia Europejska | Europejski Fundusz Społeczny' height={{ base: '50px', lg: '60px', xl: '70px' }} />
        </SimpleGrid>
        <Text fontSize={12} color='gray.600' textAlign='center'>Platforma współfinansowana ze środków Europejskiego Funduszu Społecznego w ramach Regionalnego Programu Operacyjnego Województwa Lubelskiego na lata 2014-2020.</Text>
      </VStack>
    </Box>
  )
}

export default UEInfo

UEInfo.propTypes = {
  stealMargin: PropTypes.bool
}
