import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import SetPage from '../../pages/dashboard/SetPage'
import SetContentContext from '../../contexts/SetContentContext'

function SetLearning({ page = 0 }) {
  const { content } = useContext(SetContentContext)

  return (
    content ? <SetPage page={page} /> : null
  )
}

SetLearning.propTypes = {
  page: PropTypes.number,
  content: PropTypes.oneOfType([
    PropTypes.shape({
      pages: PropTypes.arrayOf(PropTypes.object)
    }).isRequired,
    PropTypes.oneOf([null])
  ])
}

export default SetLearning
