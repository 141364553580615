import React, { useState } from 'react'
import { Link, VStack, Box, Button, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, FormControl, FormErrorMessage, FormLabel, Input, createStandaloneToast } from '@chakra-ui/react'

import Translate from '../../components/Translate'
import { useApiFetch } from '../../utils/api'

function TransferAccept() {
  const initialErrors = { title: '', date: '' }

  const api = useApiFetch()
  const [submitting, setSubmitting] = useState(false)
  const [data, setData] = useState({ title: '', date: '' })
  const [errors, setErrors] = useState(initialErrors)

  const toast = createStandaloneToast()

  const { onOpen, onClose, isOpen } = useDisclosure()

  const onSubmit = () => {
    setSubmitting(true)
    setErrors(initialErrors)

    api('/admin/payments/transfer', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then(({ uuid }) => {
        setSubmitting(false)
        onClose()

        toast({
          title: <><Translate>Order has been marked as paid!</Translate> <Link textDecoration='underline' href={`/admin/orders/${uuid}`}><Translate>Go to order</Translate></Link></>,
          status: 'success'
        })
      })
      .catch(async (err) => {
        setSubmitting(false)
        const body = await err.json()

        setErrors(body.errors || initialErrors)

        toast({
          title: <Translate>{ body.message || 'Internal API error'}</Translate>,
          status: 'error'
        })
      })
  }

  return (
    <Box>
      <Button variant='brand-solid' onClick={onOpen}>
        <Translate>Accept transfer</Translate>
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Translate>Accept transfer</Translate>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={2}>
              <FormControl id="transfer-title" isRequired isInvalid={errors.title}>
                <FormLabel>
                  <Translate>Transfer title</Translate>
                </FormLabel>
                <Input placeholder='LS-XXXXXXXXXX' onChange={(e) => setData({ ...data, title: e.target.value })} maxLength={13} />
                <FormErrorMessage><Translate>{ errors.title }</Translate></FormErrorMessage>
              </FormControl>
              <FormControl id="transfer-date" isRequired isInvalid={errors.date}>
                <FormLabel>
                  <Translate>Transfer receive date</Translate>
                </FormLabel>
                <Input type='date' onChange={(e) => setData({ ...data, date: e.target.value })} maxLength={13} />
                <FormErrorMessage><Translate>{ errors.date }</Translate></FormErrorMessage>
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button variant="brand-ghost" mr={3} onClick={onClose}>
              <Translate>Close</Translate>
            </Button>
            <Button onClick={onSubmit} isLoading={submitting}>
              <Translate>Submit</Translate>
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  )
}

export default TransferAccept
