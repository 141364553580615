import React, { useEffect, useState } from 'react'
import { Heading, SimpleGrid, Box, Text } from '@chakra-ui/react'
import randomstring from 'randomstring'

import Set from '../../components/sets/Set'
import Translate from '../../components/Translate'
import { useApiFetch } from '../../utils/api'

function Sets() {

  const [sets, setSets] = useState(false)

  const api = useApiFetch()

  useEffect(() => {

    api('/sets/bought')
      .then((res) => {
        setSets(res)
      })
      .catch(() => {})
  }, [])

  const setsStorage = sets !== false ? sets : new Array(6)
    .fill({})
    .map(() => ({ id: randomstring.generate({ length: 8 }), image: null }))

  return (
    <Box>
      <Heading mb={5}>
        <Translate>Your sets</Translate>
      </Heading>
      {
        setsStorage.length ?
          <SimpleGrid columns={{ base: 1, sm: 2, lg: 3 }} spacing={10}>
            {
              setsStorage.map(set => (
                <Set key={'nss-'+ set.id} myKey={'nss-'+ set.id} data={set} loaded={!!sets} toBuy={false}  />
              ))
            }
          </SimpleGrid>
          : <Text>
            <Translate>You don&apos;t own any sets</Translate>.
          </Text>
      }

    </Box>
  )
}

export default Sets
