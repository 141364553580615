import React, { useMemo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { Menu, MenuButton, MenuList, MenuItemOption, Button, Image, MenuOptionGroup, HStack, Text, CircularProgress } from '@chakra-ui/react'

import { useApiFetch } from '../../utils/api'

const Flag = ({ url }) => (
  <Image src={url} h='14px' w='21px' style={{ opacity: 1 }}/>
)

function CountryInput({ onChange: sendHigher, defaultValue }) {

  const api = useApiFetch()

  const [countries, setCountries] = useState(null)
  const [selected, setSelected] = useState(defaultValue || 'PL')

  const handleOnClick = (value) => {
    setSelected(value)
    if (sendHigher) sendHigher(value)
  }

  useEffect(() => {
    api('/countries')
      .then(data => {
        setCountries(data)
      })
      .catch(() => {})
  }, [])

  useEffect(() => {
    setSelected(defaultValue)
  }, [defaultValue])

  const currentSelected = useMemo(() => {
    if (countries) {

      const { name, flag } = countries.filter(({ shortcode }) => selected === shortcode)[0]

      return <HStack spacing={2}><Flag url={flag} /><Text>{ name }</Text></HStack>
    }
  }, [selected, countries])

  return countries ?
    <Menu>
      <MenuButton as={Button} rightIcon={<ChevronDownIcon />} w={'50%'}>
        { currentSelected }
      </MenuButton>
      <MenuList overflowY='auto' maxH='250px' >
        <MenuOptionGroup onChange={handleOnClick}>
          {
            countries.map(({ shortcode, flag, name }) => (
              <MenuItemOption
                key={shortcode}
                value={shortcode}
                type='checkbox'
                isChecked={selected === shortcode}
              >
                <HStack>
                  <Flag key={shortcode} url={flag} />
                  <Text>{ name }</Text>
                </HStack>
              </MenuItemOption>
            ))
          }
        </MenuOptionGroup>
      </MenuList>
    </Menu>
    : <CircularProgress isIndeterminate size='1.4em' color='brand.400' my={{ base: 6, md: 0 }} />
}

export default CountryInput

Flag.propTypes = {
  url: PropTypes.string
}

CountryInput.propTypes = {
  defaultValue: PropTypes.string,
  onChange: PropTypes.func
}
