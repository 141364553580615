import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, VStack, FormControl, FormLabel, FormErrorMessage, Input, Button } from '@chakra-ui/react'
import { useApiFetch } from '../../../utils/api'
import Translate from '../../../components/Translate'

function DataForm({ onLoad: sendIsLoaded, afterSubmit }) {
  const [data, setData] = useState({
    firstname: '',
    lastname: ''
  })

  const [errors, setErrors] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)

  const api = useApiFetch()

  const handleInput = (e, name) => {
    setData({
      ...data,
      [name]: e.target ? (
        e.target.type === 'checkbox' ?
          e.target.checked
          : e.target.value
      )
        : e
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    setIsSubmitting(true)
    setErrors({})

    setTimeout(() => {
      setIsSubmitting(false)
      afterSubmit()
    }, 3000)
  }

  useEffect(() => {
    api('/client')
      .then(client => {
        const { firstname, lastname } = client

        setData({ firstname, lastname })
        sendIsLoaded()
      })
      .catch(() => {})
  }, [])

  return (
    <Box w='100%'>
      <form method='POST' onSubmit={handleSubmit}>
        <VStack spacing={4} mb={4}>
          <FormControl isInvalid={errors.firstname}>
            <FormLabel>
              <Translate>Firstname</Translate>
            </FormLabel>
            <Input type='text' name='firstname' onChange={e => handleInput(e, 'firstname')} value={data.firstname} />
            <FormErrorMessage>{ errors.firstname }</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={errors.lastname}>
            <FormLabel>
              <Translate>Lastname</Translate>
            </FormLabel>
            <Input type='text' name='lastname' onChange={e => handleInput(e, 'lastname')} value={data.lastname} />
            <FormErrorMessage>{ errors.lastname }</FormErrorMessage>
          </FormControl>
          <Button w={{ base: '100%', md: 'initial' }} variant='brand-solid' type='submit' isLoading={isSubmitting}>
            <Translate>Save</Translate>
          </Button>
        </VStack>
      </form>
    </Box>
  )
}

DataForm.propTypes = {
  onLoad: PropTypes.func,
  afterSubmit: PropTypes.func
}

export default DataForm
