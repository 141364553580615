import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { ChakraProvider, extendTheme, Box } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'

const customTheme = extendTheme({
  useSystemColorMode: true,
  initialColorMode: 'light',
  styles: {
    color: 'brand.400'
  },
  colors: {
    brand: {
      600: '#043659',
      500: '#006386',
      400: '#009CA3',
      300: '#60BAA4',
      200: '#B4DBD2',
      100: '#FAFAFF',
      50: '#D79514'
    }
  },
  fonts: {
    body: 'Ubuntu, sans-serif',
    heading: 'Ubuntu, sans-serif',
    text: 'Ubuntu, sans-serif'
  },
  components: {
    TagLabel: {
      defaultProps: {
        colorScheme: 'brand',
        focusBorderColor: 'brand'
      },
      variants: {
        'brand-solid': {
          bg: 'brand.400',
          color: '#FFF'
        }
      }
    },
    Checkbox: {
      defaultProps: {
        colorScheme: 'brand',
        focusBorderColor: 'brand'
      },
      baseStyle: {
        control: {
          bg: 'white',
        }
      }
    },
    Input: {
      defaultProps: {
        colorScheme: 'brand',
        focusBorderColor: 'brand'
      }
    },
    Button: {
      defaultProps: {
        colorScheme: 'brand',
        focusBorderColor: 'brand',
        variant: 'brand-solid'
      },
      variants: {
        'brand-solid': (props) => ({
          bg: mode('brand.400', 'brand.400')(props),
          color: 'white',
          _hover: {
            bg: mode('brand.500', 'brand.500')(props),
            _disabled: {
              bg: mode('brand.400', 'brand.400')(props)
            }
          }
        }),
        'brand-ghost': (props) => ({
          bg: mode('transparent', 'transparent')(props),
          color: mode('brand.500', 'white')(props),
          _hover: {
            bg: mode('brand.200', 'brand.600')(props)
          }
        })
      }
    },
    Heading: {
      baseStyle: {
        colorScheme: 'brand',
        fontWeight: 500
      }
    },
    Link: {
      baseStyle: {
        color: 'blue.600',
        _hover: {
          textDecoration: 'none',
          color: 'blue.500'
        }
      }
    }
  }
})

function App({ children }) {

  useEffect(() => {
    const addr = new URL(window.location)

    if (addr.hostname.substr(0, 4) === 'www.') {
      window.location.href = addr.href.replace('www.', '')
    }
  }, [])

  return (
    <ChakraProvider theme={customTheme}>
      <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>
        <Box minH='100vh'>
          { children }
        </Box>
      </GoogleReCaptchaProvider>
    </ChakraProvider>
  )
}

export default App

App.propTypes = {
  children: PropTypes.element
}
