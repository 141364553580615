import React from 'react'
import { VStack, FormControl, FormLabel, Switch, useColorMode } from '@chakra-ui/react'
import Translate from '../../../components/Translate'

function LearnForm() {
  const { colorMode, toggleColorMode }  = useColorMode()

  return (
    <form method='POST'>
      <VStack>
        <FormControl display="flex" alignItems="center">
          <FormLabel htmlFor="dark-mode" mb="0">
            <Translate>Enable dark mode</Translate>
          </FormLabel>
          <Switch id="dark-mode" isChecked={colorMode === 'dark'} onChange={toggleColorMode} />
        </FormControl>

        {/* <FormControl display="flex" alignItems="center">
          <FormLabel htmlFor="animations" mb="0">
            <Translate>Enable animations</Translate>
          </FormLabel>
          <Switch id="animations" />
        </FormControl> */}

      </VStack>
    </form>
  )
}

export default LearnForm
