import React from 'react'
import { Redirect } from 'react-router'
import { Box } from '@chakra-ui/react'

function Home() {

  return (
    <Box>
      <Redirect to={'/platform'} />
    </Box>
  )
}

export default Home
