import React, { useState, useEffect } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { MdAdminPanelSettings, MdArrowBack, MdCategory, MdDashboard, MdPeople, MdReceiptLong, MdSchool } from 'react-icons/md'

import DashboardMenuItem from '../dashboard/DashboardMenuItem'
import DashboardMenuDivider from '../dashboard/DashboardMenuDivider'
import Translate from '../Translate'

import { useAuth } from '../../utils/auth'

function AdminMenu() {

  const [role, setRole] = useState(false)

  const { user } = useAuth()
  const { url } = useRouteMatch()

  useEffect(() => {
    user(function({ usergroup }) {
      setRole(usergroup)
    })
  }, [])

  return (
    <>
      <DashboardMenuItem to={'/admin'} icon={MdDashboard}>
        <Translate>Dashboard</Translate>
      </DashboardMenuItem>

      {
        role === 'admin'
          ? <>
            <DashboardMenuDivider>
              <Translate>Sale</Translate>
            </DashboardMenuDivider>

            <DashboardMenuItem to={`${url}/orders`} icon={MdReceiptLong}>
              <Translate>Orders</Translate>
            </DashboardMenuItem>

            <DashboardMenuItem to={`${url}/clients`} icon={MdPeople}>
              <Translate>Clients</Translate>
            </DashboardMenuItem>

            <DashboardMenuDivider>
              <Translate>Manage</Translate>
            </DashboardMenuDivider>


            <DashboardMenuItem to={`${url}/sets`} icon={MdSchool}>
              <Translate>Sets</Translate>
            </DashboardMenuItem>

            <DashboardMenuItem to={`${url}/products`} icon={MdCategory}>
              <Translate>Products</Translate>
            </DashboardMenuItem>

            <DashboardMenuItem to={`${url}/settings`} icon={MdAdminPanelSettings}>
              <Translate>Settings</Translate>
            </DashboardMenuItem>
          </>
          : null
      }

      <DashboardMenuDivider />

      <DashboardMenuItem to={'/platform'} icon={MdArrowBack}>
        <Translate>Back to platform</Translate>
      </DashboardMenuItem>
    </>
  )
}

export default AdminMenu
