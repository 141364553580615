export const dmy_date = (timestamp) => {
  const date = new Date(timestamp * 1000)

  return new String(date.getDate()).padStart(2, '0') + '.' +
  new String(date.getMonth()).padStart(2, '0') + '.' +
  date.getFullYear()
}

export const dmyhm_date = (timestamp) => {
  const date = new Date(timestamp * 1000)

  return new String(date.getDate()).padStart(2, '0') + '.' +
  new String(date.getMonth()).padStart(2, '0') + '.' +
  date.getFullYear() + ' ' +
  new String(date.getHours()).padStart(2, '0') + ':' +
  new String(date.getMinutes()).padStart(2, '0')
}
